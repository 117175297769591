/* eslint eqeqeq: "off" */
import React, { useState } from 'react'
import { get, flow, flatMap, map, filter } from 'lodash/fp'
import { useParams } from 'react-router-dom'
import { fDate } from '../utils/formatTime'
import {
  Paper,
  Box,
  Container,
  Typography,
  Divider,
  Dialog,
  DialogContent,
  Button,
  Stack,
  Menu,
  MenuItem,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { Icon } from '@iconify/react'
import plusFill from '@iconify/icons-eva/plus-fill'
import outlineAttachment from '@iconify/icons-ic/outline-attachment'
import baselineDraw from '@iconify/icons-ic/baseline-draw'
import { useQuery, useMutation } from '@apollo/client'
import camelize from '../utils/camelize'
import Page from '../components/Page'
import NewPriceForm from '../forms/NewPriceForm'
import CaseServicesForm from '../forms/CaseServicesForm'
import { FETCH_CASE, FETCH_CASE_SERVICES_PRICES } from '../store/queries'
import { UPDATE_CASE_SERVICE_PRICES } from '../store/mutations'

const columns = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'date',
    headerName: 'Date',
    flex: 0.3,
    valueFormatter: ({ value }) => fDate(value),
  },
  {
    field: 'cost',
    headerName: 'Price',
    flex: 0.2,
    valueFormatter: ({ value }) => '$' + value,
  },
  { field: 'providerName', headerName: 'Provider', flex: 0.5 },
]

export const Case = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [showCaseServicesForm, setShowCaseServicesForm] = useState(false)
  const { caseId } = useParams()
  const { error, loading, data } = useQuery(FETCH_CASE, {
    variables: { id: caseId },
  })
  const {
    // eslint-disable-next-line no-unused-vars
    data: caseServicesData,
    loading: caseServicesLoading,
    refetch: refetchCaseServicesPrices,
  } = useQuery(FETCH_CASE_SERVICES_PRICES, {
    variables: { id: caseId },
  })
  if (error) return <div>Error</div>
  if (loading) return <div>Loading...</div>
  const attachmentTypes = flow(camelize, get('attachmentTypes'))(data)
  const _case = flow(camelize, get('cases.0'))(data)
  console.log(_case.caseServices)
  _case.prices = flow(
    get('caseServices'),
    flatMap(get('caseServicesPrices')),
    flatMap(get('price'))
  )(_case)
  _case.caseServices = flow(
    get('caseServices'),
    map(cs => ({ ...cs, caseServiceId: cs.id, ...cs.service })),
    map(s => ({
      ...s,
      prices: filter(
        p => p.provider.region.code === _case.region.code,
        s.prices
      ),
    }))
  )(_case)
  console.log({ _case })
  const open = Boolean(anchorEl)
  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  return (
    <Page title={`OCP - ${_case.name}`}>
      <Dialog
        open={showCaseServicesForm}
        onClose={() => {
          refetchCaseServicesPrices()
          setShowCaseServicesForm(false)
        }}
      >
        <DialogContent>
          <CaseServicesForm caseId={caseId} />
        </DialogContent>
      </Dialog>
      <Container>
        <Paper elevation={3}>
          <Box p={3}>
            <Typography variant="h3" gutterBottom>
              Case: {_case.name}
            </Typography>
            <Box mb={2}>
              <Button
                startIcon={<Icon icon={outlineAttachment} />}
                variant="outlined"
                color="secondary"
                id="attachment-button"
                aria-controls="attachment-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleMenuClick}
              >
                Attachments
              </Button>
              <Menu
                id="attachment-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                  'aria-labelledby': 'attachment-button',
                }}
              >
                {attachmentTypes.map(({ id, name }) => (
                  <MenuItem onClick={handleMenuClose} key={id}>
                    {name}
                  </MenuItem>
                ))}
              </Menu>
              <Button
                style={{ marginLeft: '1rem' }}
                startIcon={<Icon icon={baselineDraw} />}
                variant="outlined"
                color="secondary"
                id="change-services-button"
                aria-controls="change-services-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={() => setShowCaseServicesForm(true)}
              >
                Change Services
              </Button>
            </Box>
            <Typography gutterBottom>Region: {_case.region.code}</Typography>
            <Typography gutterBottom>
              Due Date: {fDate(_case.dueDate)}
            </Typography>
            <Divider style={{ marginBottom: '20px' }} />
            <Typography variant="h4" gutterBottom>
              Services
            </Typography>
            {_case.caseServices.map(s => (
              <CaseService
                key={s.id}
                service={s}
                region={_case.region}
                casePrices={_case.prices}
                caseId={caseId}
                loading={loading || caseServicesLoading}
              />
            ))}
          </Box>
        </Paper>
      </Container>
    </Page>
  )
}

const priceToDataGridRow = price => ({
  ...price,
  providerName: price.provider.name,
})

const CaseService = ({ service, region, casePrices, caseId, loading }) => {
  // eslint-disable-next-line no-unused-vars
  const [updateCaseServicePrices, { loading: updatingPricesLoading }] =
    useMutation(UPDATE_CASE_SERVICE_PRICES, {
      refetchQueries: [FETCH_CASE_SERVICES_PRICES],
    })
  const prices = service.prices.filter(
    p => p.provider.region.code == region.code
  )
  const [isNewPriceFormOpen, setIsNewPriceFormOpen] = useState(false)
  // const [selectedPrices, setSelectedPrices] = useState([])
  // console.log({ selectedPrices })
  const priceIds = prices.map(p => p.id)
  const selectionModel = casePrices
    .map(cp => cp.id)
    .filter(cp => priceIds.includes(cp))
  const setSelectedPrices = selectedPrices => {
    updateCaseServicePrices({
      variables: {
        case_service_id: service.caseServiceId,
        data: selectedPrices.map(priceId => ({
          price_id: priceId,
          case_service_id: service.caseServiceId,
        })),
      },
    })
  }
  return (
    <Box mb={10}>
      <Dialog
        open={isNewPriceFormOpen}
        onClose={() => setIsNewPriceFormOpen(false)}
      >
        <DialogContent>
          <NewPriceForm
            initialRegion={region}
            initialService={service}
            onSubmit={() => setIsNewPriceFormOpen(false)}
          />
        </DialogContent>
      </Dialog>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
      >
        <Typography variant="h6" gutterBottom>
          {service.description}
        </Typography>
        <Button
          variant="contained"
          onClick={() => setIsNewPriceFormOpen(true)}
          startIcon={<Icon icon={plusFill} />}
        >
          New Price
        </Button>
      </Stack>
      {prices.length ? (
        <DataGrid
          columns={columns}
          rows={prices.map(priceToDataGridRow)}
          pageSize={10}
          rowsPerPageOptions={[10]}
          autoHeight
          checkboxSelection
          onSelectionModelChange={setSelectedPrices}
          selectionModel={selectionModel}
        />
      ) : (
        <Typography>No prices in this region yet</Typography>
      )}
    </Box>
  )
}

export default Case
