import faker from 'faker'
import { sample } from 'lodash'
import providers from './providers'

const generatePrice = () => ({
  id: faker.datatype.number(),
  date: faker.date.past(),
  provider: sample(providers),
  documentation: faker.internet.url(),
  cost: faker.finance.amount(100, 10000),
})

const services = [
  { id: 65, description: 'Cell enumeration phys interp', hcpcs: '86153' },
  { id: 13794, description: 'Giardia ag if', hcpcs: '87269' },
  { id: 2003, description: 'Hla i typing complete hr', hcpcs: '81379' },
  { id: 2004, description: 'X-ray male genital tract', hcpcs: '74440' },
  { id: 2005, description: 'Urinary pouch w ex wear barr', hcpcs: 'A4391' },
  { id: 2006, description: 'Antibio rx w in 10d of sympt', hcpcs: 'G9286' },
  { id: 2007, description: 'Dc eoc doc med rec', hcpcs: 'M1010' },
  { id: 2008, description: 'Cystoscopy and treatment', hcpcs: '52285' },
  { id: 2009, description: 'Removal of adenoids', hcpcs: '42836' },
  { id: 2010, description: 'Rec ftl car 3 ch rev i&r', hcpcs: '0478T' },
  { id: 2011, description: 'Cytopath c/v auto fluid redo', hcpcs: '88175' },
  { id: 2012, description: 'Amnion bio or axobio sq cm', hcpcs: 'Q4211' },
  { id: 2013, description: 'Remove eyelid lesions', hcpcs: '67801' },
  { id: 2014, description: 'Incision of brain tissue', hcpcs: '61567' },
  { id: 2015, description: 'Explore/treat eye socket', hcpcs: '67430' },
  { id: 2016, description: 'Air injection into abdomen', hcpcs: '49400' },
  { id: 2017, description: 'Prescription drug, generic', hcpcs: 'S5000' },
  { id: 2018, description: 'Not medically necessary svc', hcpcs: 'S9986' },
  { id: 2019, description: 'Hearing aid, prog, mon, ite', hcpcs: 'V5246' },
  { id: 2020, description: 'Cilastatin sodium injection', hcpcs: 'J0743' },
  { id: 2021, description: 'Drainage mouth roof lesion', hcpcs: '42000' },
  { id: 2022, description: 'Ar gene known famil variant', hcpcs: '81174' },
  { id: 2023, description: 'Intens behave ther cardio dx', hcpcs: 'G0446' },
  { id: 2024, description: 'Co-morbid condition assess', hcpcs: '1026F' },
  { id: 2025, description: 'Brachytx isodose intermed', hcpcs: '77317' },
  { id: 2026, description: 'Lead wires, pair', hcpcs: 'A4557' },
  { id: 2027, description: 'Mntr io press 24hrs/> uni/bi', hcpcs: '0329T' },
  { id: 2028, description: 'Bx breast 1st lesion mr imag', hcpcs: '19085' },
  { id: 2029, description: 'Eeg w/o vid 2-12hr cont mntr', hcpcs: '95707' },
  { id: 2030, description: 'Urinalysis auto w/scope', hcpcs: '81001' },
  { id: 2031, description: 'Cnslt before screen colonosc', hcpcs: 'S0285' },
  { id: 2032, description: 'Augmentation lower jaw bone', hcpcs: '21127' },
  { id: 2033, description: 'Exc leg/ankle tum dep 5 cm/>', hcpcs: '27634' },
  { id: 2034, description: 'Tetanus immune globulin inj', hcpcs: 'J1670' },
  { id: 2035, description: 'Butorphanol tartrate, nasal', hcpcs: 'S0012' },
  { id: 2036, description: 'Partial proctectomy', hcpcs: '45123' },
  { id: 2037, description: 'Guide cathet fluid drainage', hcpcs: '10030' },
  { id: 2038, description: 'Reconstruct elbow med ligmnt', hcpcs: '24346' },
  { id: 2039, description: 'Brnchsc w/ther aspir 1st', hcpcs: '31645' },
  { id: 2040, description: 'Hit tpn 1 liter diem', hcpcs: 'S9365' },
  { id: 2041, description: 'Dialysis repeated eval', hcpcs: '90947' },
  { id: 2042, description: 'Hlth bhv ivntj fam wo pt 1st', hcpcs: '96170' },
  { id: 2043, description: 'Feces culture aerobic bact', hcpcs: '87045' },
  { id: 2044, description: 'Repair hand deformity', hcpcs: '26580' },
  { id: 2045, description: 'Pasrr level ii', hcpcs: 'T2011' },
  { id: 2046, description: 'Replace lithium battery 1.5v', hcpcs: 'A4602' },
  { id: 2047, description: 'No trtmt chemo and her2', hcpcs: 'G2208' },
  { id: 2048, description: 'Myelography l-s spine', hcpcs: '72265' },
  { id: 2049, description: 'Treat big toe fracture', hcpcs: '28505' },
  { id: 2050, description: 'Alys npgt w/o prgrmg', hcpcs: '95970' },
  { id: 2051, description: 'Partial removal of liver', hcpcs: '47125' },
  { id: 2052, description: 'Remove ear lesion', hcpcs: '69540' },
  { id: 2053, description: 'Bronchoscopy dilate/fx repr', hcpcs: '31630' },
  { id: 2054, description: 'Assay of rbc galactokinase', hcpcs: '82759' },
  { id: 2055, description: 'Bmi doc onl fup nt doc', hcpcs: 'G8938' },
  { id: 2056, description: 'Aortic anrysm5.5-5.9cm diam', hcpcs: '9003F' },
  { id: 2057, description: 'Renal scope w/tumor resect', hcpcs: '50562' },
  { id: 2058, description: 'Biopsy of cervix', hcpcs: '57500' },
  { id: 2059, description: 'Electroconvulsive therapy', hcpcs: '90870' },
  { id: 2060, description: 'Path rpt malig cancer docd', hcpcs: '3318F' },
  { id: 2061, description: 'Repair artery rupture belly', hcpcs: '35122' },
  { id: 2062, description: 'Partial mastectomy', hcpcs: '19301' },
  { id: 2063, description: 'Ntrprof ph1/ntrnet/ehr 5/>', hcpcs: '99451' },
  { id: 2064, description: 'Assay of ethosuximide', hcpcs: '80168' },
  { id: 2065, description: 'Acute care chronic obstruct', hcpcs: 'G9681' },
  { id: 2066, description: 'Revision of larynx', hcpcs: '31400' },
  { id: 2067, description: 'Glucagon tolerance panel', hcpcs: '80422' },
  { id: 2068, description: 'Excise pharynx lesion', hcpcs: '42808' },
  { id: 2069, description: 'Shortening of thigh bone', hcpcs: '27465' },
  { id: 2070, description: 'Cogenex amnio memb per sq cm', hcpcs: 'Q4229' },
  { id: 2071, description: 'Tumor cell deplete of harvst', hcpcs: '38211' },
  { id: 2072, description: 'Patient recorded spirometry', hcpcs: '94014' },
  { id: 2073, description: 'Destruction of skin lesions', hcpcs: '17281' },
  { id: 2074, description: 'Injection for salivary x-ray', hcpcs: '42550' },
  { id: 2075, description: 'Rechanneling of artery', hcpcs: '35304' },
  { id: 2076, description: 'Repair defect of arm artery', hcpcs: '35045' },
  { id: 2077, description: 'Aspiration orbital contents', hcpcs: '67415' },
  { id: 2078, description: 'Orthodontic rem appliance tx', hcpcs: 'D8210' },
  { id: 2079, description: 'Doc of tobacco user', hcpcs: 'G9276' },
  { id: 2080, description: 'Assay of psa free', hcpcs: '84154' },
  { id: 2081, description: 'Repair blood vessel lesion', hcpcs: '35266' },
  { id: 2082, description: 'Inj testostero enanthate 1mg', hcpcs: 'J3121' },
  { id: 2083, description: 'Gastroenterology procedure', hcpcs: '91299' },
  { id: 2084, description: 'Hiv-1antibody', hcpcs: '86701' },
  { id: 2085, description: 'M.pneumon dna dir probe', hcpcs: '87580' },
  { id: 2086, description: 'Craniofacial approach skull', hcpcs: '61582' },
  { id: 2087, description: 'Rvw meds by rx/dr in rcrd', hcpcs: '1160F' },
  { id: 2088, description: 'Artificial insemination', hcpcs: '58322' },
  { id: 2089, description: 'Dental inlay metl 3/more sur', hcpcs: 'D2530' },
  { id: 2090, description: 'Incise/graft midfoot bones', hcpcs: '28305' },
  { id: 2091, description: 'Njx aa&/strd nrv nrvtg si jt', hcpcs: '64451' },
  { id: 2092, description: 'Doc med rsn no dil mac exam', hcpcs: 'G9975' },
  { id: 2093, description: 'Excision of salivary cyst', hcpcs: '42408' },
  { id: 2094, description: 'Decompression of tibia nerve', hcpcs: '28035' },
  { id: 2095, description: 'Esrd serv 1 visit p mo <2yrs', hcpcs: '90953' },
  { id: 2096, description: 'Genotype dna hepatitis b', hcpcs: '87912' },
  { id: 2097, description: 'Cd4+cell count <200cells/mm3', hcpcs: '3494F' },
  { id: 2098, description: 'Remove brain tumor w/scope', hcpcs: '62164' },
  { id: 2099, description: 'Fetal monitor/interpret only', hcpcs: '59051' },
  { id: 2100, description: 'Intmd rpr face/mm 20.1-30.0', hcpcs: '12056' },
  { id: 2101, description: 'Slco1b1 gene com variants', hcpcs: '81328' },
  { id: 2102, description: 'Phototherap keratect', hcpcs: 'S0812' },
  { id: 2103, description: 'Ecg/monitoring and analysis', hcpcs: '93271' },
  { id: 2104, description: 'N block inj hypogas plxs', hcpcs: '64517' },
  { id: 2105, description: 'Domicil/r-home visit est pat', hcpcs: '99335' },
  { id: 2106, description: 'Eeg phy/qhp>84 hr w/veeg', hcpcs: '95726' },
  { id: 2107, description: 'Bile acids cholylglycine', hcpcs: '82240' },
  { id: 2108, description: 'Removal of ocular implant', hcpcs: '65175' },
  { id: 2109, description: 'Resect nasopharynx skull', hcpcs: '61586' },
  { id: 2110, description: 'Revise two eye muscles', hcpcs: '67312' },
  { id: 2111, description: 'Bypass graft/av fist patency', hcpcs: '35686' },
  { id: 2112, description: 'Dme supply or accessory, nos', hcpcs: 'A9999' },
  { id: 2113, description: 'Growth hormone antibody', hcpcs: '86277' },
  { id: 2114, description: 'Rep perf anoper/vestib fistu', hcpcs: '46716' },
  { id: 2115, description: 'Fuse gallbladder & bowel', hcpcs: '47741' },
  { id: 2116, description: 'Incision of thigh tendons', hcpcs: '27307' },
  { id: 2117, description: 'Destruction of skin lesions', hcpcs: '17276' },
  { id: 2118, description: 'Exploration of abdomen', hcpcs: '49000' },
  { id: 2119, description: 'Insj/rplcmt subq electrode', hcpcs: '0454T' },
  { id: 2120, description: 'Alarm symp assessed-absent', hcpcs: '1070F' },
  { id: 2121, description: 'Biliary endoscopy thru skin', hcpcs: '47555' },
  { id: 2122, description: 'Hearing aid, prog, mon, cic', hcpcs: 'V5244' },
  { id: 2123, description: 'Oxytetracycline injection', hcpcs: 'J2460' },
  { id: 2124, description: 'Esphg thrsc moblj', hcpcs: '43288' },
  { id: 2125, description: 'Laparo-vag hyst w/t/o compl', hcpcs: '58554' },
  { id: 2126, description: 'Lifestyle mod 2 or 3 stage', hcpcs: 'S0341' },
  { id: 2127, description: 'Hocm <=149 mg/ml iodine, 1ml', hcpcs: 'Q9958' },
  { id: 2128, description: 'Plmt ureteral stent prq', hcpcs: '50693' },
  { id: 2129, description: 'Cmplx rpr trunk addl 5cm/<', hcpcs: '13102' },
  { id: 2130, description: 'Doc reas no statin therapy', hcpcs: 'G9940' },
  { id: 2131, description: 'Spontaneous nystagmus test', hcpcs: '92541' },
  { id: 2132, description: 'Tcat rmvl/dblk icar mas perq', hcpcs: '0644T' },
  { id: 2133, description: 'Int therapeutic restoration', hcpcs: 'D2941' },
  { id: 2134, description: 'Reconstruct elbow lat ligmnt', hcpcs: '24344' },
  { id: 2135, description: 'Remove vert xdrl body crvcl', hcpcs: '63300' },
  { id: 2136, description: 'H pylori drug admin', hcpcs: '83014' },
  { id: 2137, description: 'Doc pt dx dep/bipol', hcpcs: 'G9717' },
  { id: 2138, description: 'Treatment of fibula fracture', hcpcs: '27784' },
  { id: 2139, description: 'Remove self-contd penis pros', hcpcs: '54415' },
  { id: 2140, description: 'Sinusoidal rotational test', hcpcs: '92546' },
  { id: 2141, description: 'Injection treatment of nerve', hcpcs: '64605' },
  { id: 2142, description: 'Dialyzer, each', hcpcs: 'A4690' },
  { id: 2143, description: 'Excise foot tendon sheath', hcpcs: '28086' },
  { id: 2144, description: 'Ct/cta/mri/a chst foll rec', hcpcs: 'G9554' },
  { id: 2145, description: 'Factor viii (porcine)', hcpcs: 'J7191' },
  { id: 2146, description: 'Visco-3 inj dose', hcpcs: 'J7333' },
  { id: 2147, description: 'Insj/rplc nstim apnea stm ld', hcpcs: '0426T' },
  { id: 2148, description: 'Rechanneling of artery', hcpcs: '35301' },
  { id: 2149, description: 'Comb art/venous blood tubing', hcpcs: 'A4755' },
  { id: 2150, description: 'Incise/drain tear sac', hcpcs: '68420' },
  { id: 2151, description: 'Aud rehab pre-ling hear loss', hcpcs: '92630' },
  { id: 2152, description: 'Vein x-ray liver w/hemodynam', hcpcs: '75885' },
  { id: 2153, description: 'Remove aorta constriction', hcpcs: '33840' },
  { id: 2154, description: 'Bone srgry cmptr ct/mri imag', hcpcs: '0055T' },
  { id: 2155, description: 'Comp ext fixate strut change', hcpcs: '20697' },
  { id: 2156, description: 'Incision of hip/thigh fascia', hcpcs: '27025' },
  { id: 2157, description: 'Minimally invasive direct co', hcpcs: 'S2205' },
  { id: 2158, description: 'Replcmnt breastpump lok ring', hcpcs: 'A4286' },
  { id: 2159, description: 'Removal of lung lesion', hcpcs: '32540' },
  { id: 2160, description: 'Revise lumb artif disc addl', hcpcs: '0165T' },
  { id: 2161, description: 'Ct angio upr extrm w/o&w/dye', hcpcs: '73206' },
  { id: 2162, description: 'Injection, famotidine, 20 mg', hcpcs: 'S0028' },
  { id: 2163, description: 'Egd w/thrml txmnt gerd', hcpcs: '43257' },
  { id: 2164, description: 'Repair nasal stenosis', hcpcs: '30465' },
  { id: 2165, description: 'Intracranial vessel surgery', hcpcs: '61692' },
  { id: 2166, description: '2 em ongoing ms mo 16-18 wl', hcpcs: 'G9883' },
  { id: 2167, description: 'Assay of glycated protein', hcpcs: '82985' },
  { id: 2168, description: 'Care manag h vst new pt 60 m', hcpcs: 'G0079' },
  { id: 2169, description: 'Acylcarnitines qual', hcpcs: '82016' },
  { id: 2170, description: 'Intestine transplant live', hcpcs: '44136' },
  { id: 2171, description: 'Hit pain mgmt per diem', hcpcs: 'S9325' },
  { id: 2172, description: 'Assay of lidocaine', hcpcs: '80176' },
  { id: 2173, description: 'Elec alys vagus nrv pls gen', hcpcs: '0317T' },
  { id: 2174, description: 'Critical care addl 30 min', hcpcs: '99292' },
  { id: 2175, description: 'Limited dental tx adolescent', hcpcs: 'D8030' },
  { id: 2176, description: 'Repair of skull with graft', hcpcs: '62147' },
  { id: 2177, description: 'Oncology tx decision-mgmt', hcpcs: 'G9051' },
  { id: 2178, description: 'Laparo total proctocolectomy', hcpcs: '44210' },
  { id: 2179, description: 'Treat sternum fracture', hcpcs: '21825' },
  { id: 2180, description: 'Doppler echo exam heart', hcpcs: '93320' },
  { id: 2181, description: 'Cavopulmonary shunting', hcpcs: '33768' },
  { id: 2182, description: 'Repair of eye wound', hcpcs: '65270' },
  { id: 2183, description: 'Opps/php; train & educ serv', hcpcs: 'G0177' },
  { id: 2184, description: 'Colonoscopy w/band ligation', hcpcs: '45398' },
  { id: 2185, description: 'Screen pap by tech w md supv', hcpcs: 'P3000' },
  { id: 2186, description: 'Bkbench rcnstj don uter ven', hcpcs: '0669T' },
  { id: 2187, description: 'Mod sed oth phys/qhp 5/>yrs', hcpcs: '99156' },
  { id: 2188, description: 'Temp fml iu valve-pmp rplcmt', hcpcs: '0597T' },
  { id: 2189, description: 'Laps pelvic lymphadec', hcpcs: '38573' },
  { id: 2190, description: 'Plnning pt spec fenest graft', hcpcs: '34839' },
  { id: 2191, description: 'Diaphragm', hcpcs: 'A4266' },
  { id: 2192, description: 'Dentures mand part resin', hcpcs: 'D5212' },
  { id: 2193, description: 'Barium swallow test not req', hcpcs: '3200F' },
  { id: 2194, description: 'Consultation with family', hcpcs: '90887' },
  { id: 2195, description: 'Hdr rdncl skn surf brachytx', hcpcs: '77768' },
  { id: 2196, description: 'Orbitocranial approach/skull', hcpcs: '61592' },
  { id: 2197, description: 'Hepatitis delta agent ag ia', hcpcs: '87380' },
  { id: 2198, description: 'Repair nasal septum defect', hcpcs: '30630' },
  { id: 2199, description: 'Nerve pedicle transfer', hcpcs: '64905' },
  { id: 2200, description: 'Nk cells total count', hcpcs: '86357' },
  { id: 2201, description: 'Excision of mesentery lesion', hcpcs: '44820' },
  { id: 2202, description: 'Hit anti-hemophil diem', hcpcs: 'S9345' },
  { id: 2203, description: 'Chemical peel nonfacial', hcpcs: '15792' },
  { id: 2204, description: 'Dermagraft', hcpcs: 'Q4106' },
  { id: 2205, description: 'Bk pain vas 6-20wk > 3', hcpcs: 'G2137' },
  { id: 2206, description: 'Detect agnt mult dna direc', hcpcs: '87800' },
  { id: 2207, description: 'Repair/graft hand tendon', hcpcs: '26392' },
  { id: 2208, description: 'Remv&replc pm gen mult leads', hcpcs: '33229' },
  { id: 2209, description: 'Kidney endoscopy', hcpcs: '50551' },
  { id: 2210, description: 'Trim nail(s) any number', hcpcs: '11719' },
  { id: 2211, description: 'Surg exp root surf molar', hcpcs: 'D3503' },
  { id: 2212, description: 'Resin two surfaces-anterior', hcpcs: 'D2331' },
  { id: 2213, description: 'Abltj perc lxtr/perph nrv', hcpcs: '0441T' },
  { id: 2214, description: 'Mon anesth care', hcpcs: 'G9654' },
  { id: 2215, description: 'Cmptr ophth dx img ant segmt', hcpcs: '92132' },
  { id: 2216, description: 'Onlay cst hgh nbl mtl 2 srfc', hcpcs: 'D6610' },
  { id: 2217, description: 'Exc rect tum transanal part', hcpcs: '45171' },
  { id: 2218, description: 'Diazoxide injection', hcpcs: 'J1730' },
  { id: 2219, description: 'Icut allergy titrate-airborn', hcpcs: '95027' },
  { id: 2220, description: 'Repl bat t.e.n.s. own by pt', hcpcs: 'A4630' },
  { id: 2221, description: 'Explore/irrigate tear ducts', hcpcs: '68840' },
  { id: 2222, description: 'Test for chlorohydrocarbons', hcpcs: '82441' },
  { id: 2223, description: 'Trluml perip athrc brchiocph', hcpcs: '0237T' },
  { id: 2224, description: 'Post chmbr intraocular lens', hcpcs: 'V2632' },
  { id: 2225, description: 'Culture type pulse field gel', hcpcs: '87152' },
  { id: 2226, description: 'Artery x-rays spine', hcpcs: '75705' },
  { id: 2227, description: 'Place catheter in vein', hcpcs: '36012' },
  { id: 2228, description: 'Insert card electrodes dual', hcpcs: '33211' },
  { id: 2229, description: 'Insert tun ip cath w/port', hcpcs: '49419' },
  { id: 2230, description: 'Repair blood vessel lesion', hcpcs: '35201' },
  { id: 2231, description: 'Opn tx nasomax fx w/graft', hcpcs: '21348' },
  { id: 2232, description: 'Free jejunum flap microvasc', hcpcs: '43496' },
  { id: 2233, description: 'Docu dx degen neuro', hcpcs: 'M1127' },
  { id: 2234, description: 'Colon ca scrn; barium enema', hcpcs: 'G0120' },
  { id: 2235, description: 'Cast sup lng arm splnt ped f', hcpcs: 'Q4020' },
  { id: 2236, description: 'Removal of esophagus', hcpcs: '43124' },
  { id: 2237, description: 'Remove & treat brain lesion', hcpcs: '61544' },
  { id: 2238, description: 'Amalgam two surfaces permane', hcpcs: 'D2150' },
  { id: 2239, description: 'Anemia plan of care docd', hcpcs: '0516F' },
  { id: 2240, description: 'Drain/inj joint/bursa w/o us', hcpcs: '20600' },
  { id: 2241, description: 'Rsa spine exam', hcpcs: '0348T' },
  { id: 2242, description: 'Hospital/asc call', hcpcs: 'D9420' },
  { id: 2243, description: 'Alglucerase injection', hcpcs: 'J0205' },
  { id: 2244, description: 'Repair palate', hcpcs: '42182' },
  { id: 2245, description: 'Hh respiratory thrpy in eval', hcpcs: 'S5180' },
  { id: 2246, description: 'Coronary artery bypass/reop', hcpcs: '33530' },
  { id: 2247, description: 'Free myo/skin flap microvasc', hcpcs: '15756' },
  { id: 2248, description: 'Rmvl esophgl sphnctr dev', hcpcs: '43285' },
  { id: 2249, description: 'Fosphenytoin inj pe', hcpcs: 'Q2009' },
  { id: 2250, description: 'Abd imag w/us, ct or mri', hcpcs: 'G9455' },
  { id: 2251, description: 'Post-d/c h vst new pt 60 m', hcpcs: 'G2004' },
  { id: 2252, description: 'Vent mgmt inpat subq day', hcpcs: '94003' },
  { id: 2253, description: 'Dimethyl sulfoxide 50% 50 ml', hcpcs: 'J1212' },
  { id: 2254, description: 'Remove eyelid lesions', hcpcs: '67805' },
  { id: 2255, description: 'Partial removal finger bone', hcpcs: '26235' },
  { id: 2256, description: 'Acyclovir injection', hcpcs: 'J0133' },
  { id: 2257, description: 'Inj enfort vedo-ejfv 0.25mg', hcpcs: 'J9177' },
  { id: 2258, description: 'Penis study', hcpcs: '54250' },
  { id: 2259, description: 'Dentures rebase cmplt mand', hcpcs: 'D5711' },
  { id: 2260, description: 'Provision splnt intracoronal', hcpcs: 'D4320' },
  { id: 2261, description: 'Epidrm autogrft trnk/arm/leg', hcpcs: '15110' },
  { id: 2262, description: 'Mcp joint arthroscopy dx', hcpcs: '29900' },
  { id: 2263, description: 'Pjp proph ordered cd4 low', hcpcs: 'G9223' },
  { id: 2264, description: 'Hba1c in office testing', hcpcs: 'D0411' },
  { id: 2265, description: 'Pt unbl cmplt lepf prom', hcpcs: 'G9731' },
  { id: 2266, description: 'Clsd red comp malar/zygma fx', hcpcs: 'D7760' },
  { id: 2267, description: 'Curette/treat cornea', hcpcs: '65435' },
  { id: 2268, description: 'Sperm isolation complex', hcpcs: '89261' },
  { id: 2269, description: 'Exploration of kidney', hcpcs: '50010' },
  { id: 2270, description: 'Blood typing serologic abo', hcpcs: '86900' },
  { id: 2271, description: 'Direct immunofluorescence', hcpcs: 'D0482' },
  { id: 2272, description: 'Apligraf', hcpcs: 'Q4101' },
  { id: 2273, description: 'Porfimer sodium injection', hcpcs: 'J9600' },
  { id: 2274, description: 'Ins vag brachytx device', hcpcs: '57156' },
  { id: 2275, description: 'Unlisted anesth procedure', hcpcs: '01999' },
  { id: 2276, description: 'Continent stoma catheter', hcpcs: 'A5082' },
  { id: 2277, description: 'Destruction of skin lesions', hcpcs: '17286' },
  { id: 2278, description: 'Extracranial bilat study', hcpcs: '93880' },
  { id: 2279, description: 'Interrogation vad in person', hcpcs: '93750' },
  { id: 2280, description: 'Antithrombin iii injection', hcpcs: 'J7197' },
  { id: 2281, description: 'Removal of rectum', hcpcs: '45112' },
  { id: 2282, description: 'Anesth hip joint procedure', hcpcs: '01200' },
  { id: 2283, description: 'Tendon excision palm/finger', hcpcs: '26145' },
  { id: 2284, description: 'Chimerism anal w/cell select', hcpcs: '81268' },
  { id: 2285, description: 'Assay of lipoprotein(a)', hcpcs: '83695' },
  { id: 2286, description: 'Chemo iv infusion addl hr', hcpcs: '96415' },
  { id: 2287, description: 'Anoscopy and biopsy', hcpcs: '46606' },
  { id: 2288, description: 'Ost pch clsd w/o bar w filtr', hcpcs: 'A4418' },
  { id: 2289, description: 'Bls', hcpcs: 'A0428' },
  { id: 2290, description: 'Arthroflex', hcpcs: 'Q4125' },
  { id: 2291, description: 'Remove vert idrl body crvcl', hcpcs: '63304' },
  { id: 2292, description: 'Intermittent urinary cath', hcpcs: 'A4353' },
  { id: 2293, description: 'Belimumab injection', hcpcs: 'J0490' },
  { id: 2294, description: 'Compres burn garment panty', hcpcs: 'A6511' },
  { id: 2295, description: 'Hi inten serv for SIP model', hcpcs: 'G2020' },
  { id: 2296, description: 'Methacholine chloride, neb', hcpcs: 'J7674' },
  { id: 2297, description: 'Indium in-111 auto platelet', hcpcs: 'A9571' },
  { id: 2298, description: 'Assessment of a patient', hcpcs: 'D0191' },
  { id: 2299, description: 'Biopsy/removal lymph nodes', hcpcs: '38510' },
  { id: 2300, description: 'Exc abd tum over 10 cm', hcpcs: '49205' },
  { id: 2301, description: 'Dilation and curettage', hcpcs: '58120' },
  { id: 2302, description: '2 cc sterile syringe&needle', hcpcs: 'A4207' },
  { id: 2303, description: 'Off base opioid tx, add30', hcpcs: 'G2088' },
  { id: 2304, description: 'Elbow arthroscopy/surgery', hcpcs: '29838' },
  { id: 2305, description: 'Gene test beta-thalassemia', hcpcs: 'S3846' },
  { id: 2306, description: 'X-ray exam of shoulder blade', hcpcs: '73010' },
  { id: 2307, description: 'Vitamin b-12', hcpcs: '82607' },
  { id: 2308, description: 'Budesonide comp con', hcpcs: 'J7634' },
  { id: 2309, description: 'Contact lens pmma bifocal', hcpcs: 'V2502' },
  { id: 2310, description: 'Gauze > 48 sq in water/salne', hcpcs: 'A6230' },
  { id: 2311, description: 'Perq art m-thrombect &/nfs', hcpcs: '61645' },
  { id: 2312, description: 'Induced abortion 25-28 wks', hcpcs: 'S2265' },
  { id: 2313, description: 'Ace arb arni', hcpcs: 'G2092' },
  { id: 2314, description: 'Veeg ea 12-26hr intmt mntr', hcpcs: '95715' },
  { id: 2315, description: 'Pt edu re alcoh drnkng done', hcpcs: '4158F' },
  { id: 2316, description: 'Major vessel shunt', hcpcs: '33750' },
  { id: 2317, description: 'Cmplx rpr s/a/l 1.1-2.5 cm', hcpcs: '13120' },
  { id: 2318, description: 'Mecp2 gene known variant', hcpcs: '81303' },
  { id: 2319, description: 'Stoma plug or seal, any type', hcpcs: 'A5081' },
  { id: 2320, description: 'Exc shoulder tum deep 5 cm/>', hcpcs: '23073' },
  { id: 2321, description: 'Anesth knee joint procedure', hcpcs: '01380' },
  { id: 2322, description: 'Treat foot dislocation', hcpcs: '28575' },
  { id: 2323, description: 'Exc f/e/e/n/l mal+mrg 3.1-4', hcpcs: '11644' },
  { id: 2324, description: 'I131 iodide sol, dx', hcpcs: 'A9529' },
  { id: 2325, description: 'Azithromycin dihydrate, oral', hcpcs: 'Q0144' },
  { id: 2326, description: 'Percutaneous islet celltrans', hcpcs: 'G0341' },
  { id: 2327, description: 'Anticonv thxpy for 6 mos/>', hcpcs: '4230F' },
  { id: 2328, description: 'Intensive outpatient psychia', hcpcs: 'S9480' },
  { id: 2329, description: 'Resect hip tum w/innom bone', hcpcs: '27077' },
  { id: 2330, description: 'Rbc sed rate automated', hcpcs: '85652' },
  { id: 2331, description: 'Removal of hydrocele', hcpcs: '55500' },
  { id: 2332, description: 'Create tear duct drain', hcpcs: '68750' },
  { id: 2333, description: 'Treat ectopic pregnancy', hcpcs: '59135' },
  { id: 2334, description: 'Resect face/scalp tum 2 cm/>', hcpcs: '21016' },
  { id: 2335, description: 'Bitolterol mesylate comp unt', hcpcs: 'J7629' },
  { id: 2336, description: 'Repair radius & ulna', hcpcs: '25415' },
  { id: 2337, description: 'Endoscopic us exam esoph', hcpcs: '43237' },
  { id: 2338, description: 'Myocrd img pet rst&strs ct', hcpcs: '78431' },
  { id: 2339, description: 'Skin substitute, nos', hcpcs: 'Q4100' },
  { id: 2340, description: 'Decompression of leg', hcpcs: '27892' },
  { id: 2341, description: 'Pinworm exam', hcpcs: '87172' },
  { id: 2342, description: 'No xray/ct/ et al ordd', hcpcs: '3320F' },
  { id: 2343, description: 'Rn services up to 15 minutes', hcpcs: 'T1002' },
  { id: 2344, description: 'Auto quan c plaq cptr alys', hcpcs: '0625T' },
  { id: 2345, description: 'Cinacalcet, esrd on dialysis', hcpcs: 'J0604' },
  { id: 2346, description: 'Sterile needle', hcpcs: 'A4215' },
  { id: 2347, description: 'Removal of scrotum lesion', hcpcs: '55120' },
  { id: 2348, description: 'Pseudoaneurysm injection trt', hcpcs: '36002' },
  { id: 2349, description: 'Cone beam ct capture limited', hcpcs: 'D0380' },
  { id: 2350, description: 'Hereditary retinal disorders', hcpcs: '81434' },
  { id: 2351, description: 'Acute nursing facility care', hcpcs: 'G9685' },
  { id: 2352, description: 'Diuretic thxpy for 6 mos/>', hcpcs: '4221F' },
  { id: 2353, description: 'Alcohol/substance abuse skil', hcpcs: 'T1012' },
  { id: 2354, description: 'Njx noncmpnd sclrsnt mlt vn', hcpcs: '36466' },
  { id: 2355, description: 'Amputation of toe', hcpcs: '28820' },
  { id: 2356, description: 'Pinworm examinations', hcpcs: 'Q0113' },
  { id: 2357, description: 'Community/work reintegration', hcpcs: '97537' },
  { id: 2358, description: 'Chromosome analysis 20-25', hcpcs: '88264' },
  { id: 2359, description: 'Treat trigeminal nerve', hcpcs: '61790' },
  { id: 2360, description: 'X-ray bend only l-s spine', hcpcs: '72120' },
  { id: 2361, description: 'Basiliximab', hcpcs: 'J0480' },
  { id: 2362, description: 'Tgfbi gene common variants', hcpcs: '81333' },
  { id: 2363, description: 'Echo exam of fetal heart', hcpcs: '76827' },
  { id: 2364, description: 'Remove bilat space main, max', hcpcs: 'D1526' },
  { id: 2365, description: 'Acth stimulation panel', hcpcs: '80406' },
  { id: 2366, description: 'Repair & revise nerve add-on', hcpcs: '64874' },
  { id: 2367, description: 'Excision of esophagus lesion', hcpcs: '43100' },
  { id: 2368, description: 'Tracheostoma hmes filter', hcpcs: 'A7504' },
  { id: 2369, description: 'Heart infarct image (3d)', hcpcs: '78469' },
  { id: 2370, description: 'Cysto f/urtl strix/stenosis', hcpcs: '0499T' },
  { id: 2371, description: 'Tmvi transthoracic exposure', hcpcs: '0484T' },
  { id: 2372, description: 'Urinary pouch on barr w/flng', hcpcs: 'A5073' },
  { id: 2373, description: 'Assay secretory type ii pla2', hcpcs: '0423T' },
  { id: 2374, description: 'Ajcc brstcncr stage 4 docd', hcpcs: '3380F' },
  { id: 2375, description: 'Grp a strep test performed', hcpcs: '3210F' },
  { id: 2376, description: 'X-ray exam of finger(s)', hcpcs: '73140' },
  { id: 2377, description: 'Nuclear rx iv admin', hcpcs: '79101' },
  { id: 2378, description: 'Peri-implt capslc brst compl', hcpcs: '19371' },
  { id: 2379, description: 'Scope plantar fasciotomy', hcpcs: '29893' },
  { id: 2380, description: 'Allergy immunology services', hcpcs: '95199' },
  { id: 2381, description: 'Lap radical hyst', hcpcs: '58548' },
  { id: 2382, description: 'Inj, ziv-aflibercept, 1mg', hcpcs: 'J9400' },
  { id: 2383, description: 'Semen anal vol/count/mot', hcpcs: '89320' },
  { id: 2384, description: 'Assay activated protein c', hcpcs: '85307' },
  { id: 2385, description: 'Urinalysis nonauto w/scope', hcpcs: '81000' },
  { id: 2386, description: 'Remove thyroid duct lesion', hcpcs: '60280' },
  { id: 2387, description: 'Repair eyelid wound', hcpcs: '67935' },
  { id: 2388, description: 'Biopsy of testis', hcpcs: '54505' },
  { id: 2389, description: 'Remove foreign body from eye', hcpcs: '65220' },
  { id: 2390, description: 'Dna analysis apoe alzheimer', hcpcs: 'S3852' },
  { id: 2391, description: 'Chylmd trach dna amp probe', hcpcs: '87491' },
  { id: 2392, description: 'Panitumumab injection', hcpcs: 'J9303' },
  { id: 2393, description: 'Trans care mgmt 14 day disch', hcpcs: '99495' },
  { id: 2394, description: 'Disk/foam pad +or- adhesive', hcpcs: 'A5126' },
  { id: 2395, description: 'Njx interlaminar lmbr/sac', hcpcs: '62323' },
  { id: 2396, description: 'Repair of sperm duct', hcpcs: '55400' },
  { id: 2397, description: 'Emr pwr cbl combo vad, rep', hcpcs: 'Q0493' },
  { id: 2398, description: 'Laiv3 vaccine intranasal', hcpcs: '90660' },
  { id: 2399, description: 'Behavior management', hcpcs: 'D9920' },
  { id: 2400, description: 'Cefotaxime sodium injection', hcpcs: 'J0698' },
  { id: 2401, description: 'Deferoxamine mesylate inj', hcpcs: 'J0895' },
  { id: 2402, description: 'Diabetic deluxe shoe', hcpcs: 'A5508' },
  { id: 2403, description: 'Sperm evaluation test', hcpcs: '89329' },
  { id: 2404, description: 'Doripenem injection', hcpcs: 'J1267' },
  { id: 2405, description: 'Antmc guide 3d print 1st gd', hcpcs: '0561T' },
  { id: 2406, description: 'Liletta, 52 mg', hcpcs: 'J7297' },
  { id: 2407, description: 'Riv3 vaccine no preserv im', hcpcs: '90673' },
  { id: 2408, description: 'Enterectomy cadaver donor', hcpcs: '44132' },
  { id: 2409, description: 'Athletic trn re-eval plan cr', hcpcs: '97172' },
  { id: 2410, description: 'Cont rec mvmt do 6-10 days', hcpcs: '0533T' },
  { id: 2411, description: 'Amputation follow-up surgery', hcpcs: '24925' },
  { id: 2412, description: 'Remov vert xdrl bdy lmbr/sac', hcpcs: '63303' },
  { id: 2413, description: 'Removal of eye', hcpcs: '65110' },
  { id: 2414, description: 'Peak expiratory flow rate (p', hcpcs: 'S8110' },
  { id: 2415, description: 'Cardiac rehab', hcpcs: '93797' },
  { id: 2416, description: 'Anesth perc img dx sp proc', hcpcs: '01935' },
  { id: 2417, description: 'Twist drill hole', hcpcs: '61105' },
  { id: 2418, description: 'Histrelin acetate', hcpcs: 'J1675' },
  { id: 2419, description: 'Alpha 1 proteinase inhibitor', hcpcs: 'J0256' },
  { id: 2420, description: 'Inj., eravacycline, 1 mg', hcpcs: 'J0122' },
  { id: 2421, description: 'Hlth bhv ivntj fam w/o pt ea', hcpcs: '96171' },
  { id: 2422, description: 'Hbb full gene sequence', hcpcs: '81364' },
  { id: 2423, description: 'Remove/graft foot lesion', hcpcs: '28103' },
  { id: 2424, description: 'Mometasone furoate sinus imp', hcpcs: 'J7401' },
  { id: 2425, description: 'Mri uppr extremity w/o&w/dye', hcpcs: '73220' },
  { id: 2426, description: 'Narcosynthesis', hcpcs: '90865' },
  { id: 2427, description: '2019-ncov diagnostic p', hcpcs: 'U0001' },
  { id: 2428, description: 'Pt ref oto eval', hcpcs: 'G8564' },
  { id: 2429, description: 'Pts dx w/rhabdomyolysis', hcpcs: 'G9780' },
  { id: 2430, description: 'Treat chest lining', hcpcs: '32215' },
  { id: 2431, description: 'Dtap-hep b-ipv vaccine im', hcpcs: '90723' },
  { id: 2432, description: 'Replace tunneled cv cath', hcpcs: '36578' },
  { id: 2433, description: 'Resect inferior turbinate', hcpcs: '30140' },
  { id: 2434, description: 'Chiropractic manj 5 regions', hcpcs: '98942' },
  { id: 2435, description: 'Mdd severe w/psych', hcpcs: '3091F' },
  { id: 2436, description: 'Fuse esophagus & intestine', hcpcs: '43341' },
  { id: 2437, description: 'Perq plmt bile duct stent', hcpcs: '47539' },
  { id: 2438, description: 'Doc rehab svcs considered', hcpcs: '4079F' },
  { id: 2439, description: 'Comp periodontal evaluation', hcpcs: 'D0180' },
  { id: 2440, description: 'Any hygienic item, device', hcpcs: 'A9286' },
  { id: 2441, description: 'Angioscopy', hcpcs: '35400' },
  { id: 2442, description: 'Drug test prsmv chem anlyzr', hcpcs: '80307' },
  { id: 2443, description: 'Pessary rubber, any type', hcpcs: 'A4561' },
  { id: 2444, description: 'No scrn prov rsn nos', hcpcs: 'G9925' },
  { id: 2445, description: 'Ct soft tissue neck w/dye', hcpcs: '70491' },
  { id: 2446, description: 'Clot inhibit prot c activity', hcpcs: '85303' },
  { id: 2447, description: 'L hrt art/grft angio', hcpcs: '93459' },
  { id: 2448, description: 'Alveoplasty w/o extraction', hcpcs: 'D7320' },
  { id: 2449, description: 'Inhaled bronchodilator rx', hcpcs: '4025F' },
  { id: 2450, description: 'Harvest vein for bypass', hcpcs: '35500' },
  { id: 2451, description: 'Anesth uppr arm tendon surg', hcpcs: '01714' },
  { id: 2452, description: 'Nutritional support offered', hcpcs: '4551F' },
  { id: 2453, description: 'Perq lamot/lam lumbar', hcpcs: '0275T' },
  { id: 2454, description: 'Francisella tularensis', hcpcs: '86668' },
  { id: 2455, description: 'Place cath carotd art', hcpcs: '36224' },
  { id: 2456, description: 'Breast "stacked" diep/gap', hcpcs: 'S2067' },
  { id: 2457, description: 'Amifostine', hcpcs: 'J0207' },
  { id: 2458, description: 'Dermacell, awm, porous sq cm', hcpcs: 'Q4122' },
  { id: 2459, description: 'Pt not rcvng glucoco thxpy', hcpcs: '4192F' },
  { id: 2460, description: 'Automated platelet count', hcpcs: '85049' },
  { id: 2461, description: 'Correction hallux valgus', hcpcs: '28296' },
  { id: 2462, description: 'Cath indw foley 2 way silicn', hcpcs: 'A4344' },
  { id: 2463, description: 'Inj, vincristine sul lip 1mg', hcpcs: 'J9371' },
  { id: 2464, description: 'Prpertl pel pack hemrrg trma', hcpcs: '49013' },
  { id: 2465, description: 'Ald, tv caption decoder', hcpcs: 'V5271' },
  { id: 2466, description: 'Personal care ser per diem', hcpcs: 'T1020' },
  { id: 2467, description: 'Plmt biliary drainage cath', hcpcs: '47534' },
  { id: 2468, description: 'Ob us nuchal meas 1 gest', hcpcs: '76813' },
  { id: 2469, description: 'No rec cortico>60d 1rx 600mg', hcpcs: 'G9470' },
  { id: 2470, description: 'Shortening of hand tendon', hcpcs: '26479' },
  { id: 2471, description: 'Extensive thyroid surgery', hcpcs: '60254' },
  { id: 2472, description: 'Followup eval of foot pt lop', hcpcs: 'G0246' },
  { id: 2473, description: 'Repair of anal sphincter', hcpcs: '46751' },
  { id: 2474, description: 'Hearing aid binaural glasses', hcpcs: 'V5230' },
  { id: 2475, description: 'Warf or other fda drug presc', hcpcs: 'G8967' },
  { id: 2476, description: 'Laparoscopy biopsy', hcpcs: '49321' },
  { id: 2477, description: 'Revise circulation to head', hcpcs: '61705' },
  { id: 2478, description: 'Remove femur lesion/graft', hcpcs: '27357' },
  { id: 2479, description: 'No hist type doc on report', hcpcs: 'G9284' },
  { id: 2480, description: 'Rmvl pg compnt wcs', hcpcs: '0518T' },
  { id: 2481, description: 'Remote e/m est. pt 25mins', hcpcs: 'G9985' },
  { id: 2482, description: 'Biopsy of ovary(s)', hcpcs: '58900' },
  { id: 2483, description: 'Hosp recd by pt dur msmt per', hcpcs: 'G9692' },
  { id: 2484, description: 'Biopsy of foot joint lining', hcpcs: '28050' },
  { id: 2485, description: 'Brca1&2 gene full seq alys', hcpcs: '81163' },
  { id: 2486, description: 'Med reas pt, pn, not doc', hcpcs: 'G8722' },
  { id: 2487, description: 'Ezderm', hcpcs: 'Q4136' },
  { id: 2488, description: 'Amino acids single quant', hcpcs: '82131' },
  { id: 2489, description: 'Cast supplies unlisted', hcpcs: 'Q4050' },
  { id: 2490, description: 'Thyroid met imaging', hcpcs: '78015' },
  { id: 2491, description: 'Inj paravert f jnt l/s 1 lev', hcpcs: '64493' },
  { id: 2492, description: 'Decompress small bowel', hcpcs: '44021' },
  { id: 2493, description: 'Partial removal of heart sac', hcpcs: '33030' },
  { id: 2494, description: 'Reconstruct elbow joint', hcpcs: '24361' },
  { id: 2495, description: 'Nursing fac care subseq', hcpcs: '99310' },
  { id: 2496, description: 'Treat radius fracture', hcpcs: '24650' },
  { id: 2497, description: 'Rpr s/n/ax/gen/trk7.6-12.5cm', hcpcs: '12004' },
  { id: 2498, description: 'Contour cranial bone lesion', hcpcs: '21181' },
  { id: 2499, description: 'Kit gene analys d816 variant', hcpcs: '81273' },
  { id: 2500, description: 'Implant supported retainer', hcpcs: 'D6075' },
  { id: 2501, description: 'Immed mand part dent metal', hcpcs: 'D5224' },
  { id: 2502, description: 'Dilat xst trc ndurlgc px', hcpcs: '50436' },
  { id: 2503, description: 'Assay of fetal fibronectin', hcpcs: '82731' },
  { id: 2504, description: 'Hydrotherapy', hcpcs: '97036' },
  { id: 2505, description: 'Leg pain no vas', hcpcs: 'G9949' },
  { id: 2506, description: 'Colorectal ca screen doc rev', hcpcs: '3017F' },
  { id: 2507, description: 'Oc ni pt home prog', hcpcs: 'M1108' },
  { id: 2508, description: 'Incision of large bowel', hcpcs: '44025' },
  { id: 2509, description: 'Rn care train/edu in hh', hcpcs: 'G0495' },
  { id: 2510, description: 'Myocardial imaging mcg', hcpcs: '0541T' },
  { id: 2511, description: 'Adalimumab injection', hcpcs: 'J0135' },
  { id: 2512, description: 'Esophagoscopy retro balloon', hcpcs: '43213' },
  { id: 2513, description: 'Fsodi 6-20wk >22 or chg 30pt', hcpcs: 'G2145' },
  { id: 2514, description: 'Application of finger splint', hcpcs: '29130' },
  { id: 2515, description: 'Mh svc plan dev by non-md', hcpcs: 'H0032' },
  { id: 2516, description: 'Cysto laser tx ureteral calc', hcpcs: 'S2070' },
  { id: 2517, description: 'X-ray exam of perineum', hcpcs: '74775' },
  { id: 2518, description: 'Resect radius tumor', hcpcs: '24152' },
  { id: 2519, description: 'Tc99m disofenin', hcpcs: 'A9510' },
  { id: 2520, description: 'Repair of sternum separation', hcpcs: '21750' },
  { id: 2521, description: 'Lvef <40% or dep lv sys fcn', hcpcs: 'G8934' },
  { id: 2522, description: 'Reimplantation of kidney', hcpcs: '50380' },
  { id: 2523, description: 'Icg angiography uni/bi', hcpcs: '92240' },
  { id: 2524, description: 'Autopsy (necropsy) gross', hcpcs: '88016' },
  { id: 2525, description: 'Hysterectomy/bladder repair', hcpcs: '51925' },
  { id: 2526, description: 'Reconstruction of nail bed', hcpcs: '11762' },
  { id: 2527, description: 'Extraction of lens', hcpcs: '66930' },
  { id: 2528, description: 'Endoscopic injection/implant', hcpcs: '51715' },
  { id: 2529, description: 'Reconstruction of jaw joint', hcpcs: '21240' },
  { id: 2530, description: 'Alcohol and/or drug services', hcpcs: 'H0014' },
  { id: 2531, description: '5% dextrose/normal saline', hcpcs: 'J7042' },
  { id: 2532, description: 'Routine venipuncture', hcpcs: '36415' },
  { id: 2533, description: 'Oct skn img acquisj i&r 1st', hcpcs: '0470T' },
  { id: 2534, description: 'Complex body section x-rays', hcpcs: '76102' },
  { id: 2535, description: 'Replacement breastpump adpt', hcpcs: 'A4282' },
  { id: 2536, description: 'Pt-focused hlth risk assmt', hcpcs: '96160' },
  { id: 2537, description: 'Infliximab not biosimil 10mg', hcpcs: 'J1745' },
  { id: 2538, description: 'Us guide tissue ablation', hcpcs: '76940' },
  { id: 2539, description: 'Autologous blood op salvage', hcpcs: '86891' },
  { id: 2540, description: 'Exome re-evaluation', hcpcs: '81417' },
  { id: 2541, description: 'Pt asked/cnsld aed effects', hcpcs: '6070F' },
  { id: 2542, description: 'Removal of ethmoid sinus', hcpcs: '31200' },
  { id: 2543, description: 'Glasses bone conduction', hcpcs: 'V5080' },
  { id: 2544, description: 'Repair artery w/tunnel', hcpcs: '33505' },
  { id: 2545, description: 'Inj potassium chloride', hcpcs: 'J3480' },
  { id: 2546, description: 'Intracardiac ecg (ice)', hcpcs: '93662' },
  { id: 2547, description: 'Repair/graft of bronchus', hcpcs: '31770' },
  { id: 2548, description: 'Mri neck spine w/o dye', hcpcs: '72141' },
  { id: 2549, description: 'Inj, taliglucerase alfa 10 u', hcpcs: 'J3060' },
  { id: 2550, description: 'Perq dev breast add strtctc', hcpcs: '19284' },
  { id: 2551, description: 'Insj aqueous drain dev 1st', hcpcs: '0449T' },
  { id: 2552, description: 'Biopsy of toe joint lining', hcpcs: '28054' },
  { id: 2553, description: 'Urinary bladder retention', hcpcs: '78730' },
  { id: 2554, description: 'Pt notrcvng1st antitnf txmnt', hcpcs: '6150F' },
  { id: 2555, description: 'Lens single vision not oth c', hcpcs: 'V2199' },
  { id: 2556, description: 'Us exam k transpl w/doppler', hcpcs: '76776' },
  { id: 2557, description: 'Thoracoscopy lymph node exc', hcpcs: '32674' },
  { id: 2558, description: 'Proton trmt intermediate', hcpcs: '77523' },
  { id: 2559, description: 'Partial removal of larynx', hcpcs: '31380' },
  { id: 2560, description: 'Lenticular lens, trifocal', hcpcs: 'V2321' },
  { id: 2561, description: 'Devel tst phys/qhp ea addl', hcpcs: '96113' },
  { id: 2562, description: 'Assay of carnitine', hcpcs: '82379' },
  { id: 2563, description: 'Radio/surgical implant index', hcpcs: 'D6190' },
  { id: 2564, description: 'Home visit est patient', hcpcs: '99349' },
  { id: 2565, description: 'Clostridium toxin a w/optic', hcpcs: '87803' },
  { id: 2566, description: 'Hast w/report', hcpcs: '94452' },
  { id: 2567, description: 'Cysto w/prst8 commissurotomy', hcpcs: '0619T' },
  { id: 2568, description: 'Oxygen therapy rx', hcpcs: '4030F' },
  { id: 2569, description: 'Mnl prep&insj imed rx dev', hcpcs: '20702' },
  { id: 2570, description: 'Crisis intervention per hour', hcpcs: 'S9484' },
  { id: 2571, description: 'Wndex flw, bioskn flw, 0.5cc', hcpcs: 'Q4162' },
  { id: 2572, description: 'Cdsm evidencecare', hcpcs: 'G1013' },
  { id: 2573, description: 'Remove wrist/forearm lesion', hcpcs: '25116' },
  { id: 2574, description: 'Fusion of shoulder joint', hcpcs: '23802' },
  { id: 2575, description: 'Egd lesion ablation', hcpcs: '43270' },
  { id: 2576, description: 'Somatrem injection', hcpcs: 'J2940' },
  { id: 2577, description: 'Enfuvirtide injection', hcpcs: 'J1324' },
  { id: 2578, description: 'Ligate/strip short leg vein', hcpcs: '37718' },
  { id: 2579, description: 'Abrasion lesions add-on', hcpcs: '15787' },
  { id: 2580, description: 'Biopsy of epididymis', hcpcs: '54800' },
  { id: 2581, description: 'Sho arthrs srg compl synvct', hcpcs: '29821' },
  { id: 2582, description: 'Ins cath abd/l-ext art addl', hcpcs: '36248' },
  { id: 2583, description: 'Ecog impltd brn npgt <30 d', hcpcs: '95836' },
  { id: 2584, description: 'Treatment of retinal lesion', hcpcs: '67218' },
  { id: 2585, description: 'Positional nystagmus test', hcpcs: '92542' },
  { id: 2586, description: 'L hrt cath trnsptl puncture', hcpcs: '93462' },
  { id: 2587, description: 'Incision of prostate', hcpcs: '52450' },
  { id: 2588, description: 'Ct angio abd&pelv w/o&w/dye', hcpcs: '74174' },
  { id: 2589, description: 'Radiation treatment delivery', hcpcs: 'G6013' },
  { id: 2590, description: 'Partial removal of toe', hcpcs: '28160' },
  { id: 2591, description: 'Gefitinib oral', hcpcs: 'J8565' },
  { id: 2592, description: 'X-rays transcath therapy', hcpcs: '75894' },
  { id: 2593, description: 'Destruction of skin lesions', hcpcs: '17282' },
  { id: 2594, description: 'Provide inr test mater/equip', hcpcs: 'G0249' },
  { id: 2595, description: 'Alternating pressure pad', hcpcs: 'A4640' },
  { id: 2596, description: 'Psy/dep/anx/apandicd noasse', hcpcs: 'G2122' },
  { id: 2597, description: 'Reconstruction of jaw', hcpcs: '21245' },
  { id: 2598, description: 'Ost pouch drain high output', hcpcs: 'A4412' },
  { id: 2599, description: 'Obstetrical care', hcpcs: '59400' },
  { id: 2600, description: 'Replace ureter by bowel', hcpcs: '50840' },
  { id: 2601, description: 'Njx interlaminar crv/thrc', hcpcs: '62320' },
  { id: 2602, description: 'Interfyl, 1 mg', hcpcs: 'Q4171' },
  { id: 2603, description: 'N. gonorrhoeae assay w/optic', hcpcs: '87850' },
  { id: 2604, description: 'Inj codeine phosphate /30 mg', hcpcs: 'J0745' },
  { id: 2605, description: 'Prolong prev svcs, first 30m', hcpcs: 'G0513' },
  { id: 2606, description: 'Repair of nail bed', hcpcs: '11760' },
  { id: 2607, description: 'Electro hearng aid test one', hcpcs: '92594' },
  { id: 2608, description: 'Removal of kidney stone', hcpcs: '50060' },
  { id: 2609, description: 'Car-t cll admn autologous', hcpcs: '0540T' },
  { id: 2610, description: 'X-ray guide gi dilation', hcpcs: '74360' },
  { id: 2611, description: 'Remove armpit lymph nodes', hcpcs: '38745' },
  { id: 2612, description: 'Incis 1 vertebral seg lumbar', hcpcs: '22214' },
  { id: 2613, description: 'Pt eval low complex 20 min', hcpcs: '97161' },
  { id: 2614, description: 'Treat lower leg fracture', hcpcs: '27824' },
  { id: 2615, description: 'Rafscrs hi scor < 0', hcpcs: 'G8652' },
  { id: 2616, description: 'Intmd rpr n-hf/genit 2.5cm/<', hcpcs: '12041' },
  { id: 2617, description: 'Treatment of penis lesion', hcpcs: '54110' },
  { id: 2618, description: 'Reconstruct wrist joint', hcpcs: '25445' },
  { id: 2619, description: 'Fixation of shoulder blade', hcpcs: '23400' },
  { id: 2620, description: 'Cast sup lng arm splint plst', hcpcs: 'Q4017' },
  { id: 2621, description: 'Odontics endosteal implant', hcpcs: 'D6010' },
  { id: 2622, description: 'No pap prescribed', hcpcs: 'G8850' },
  { id: 2623, description: 'Treat fx radial 3+ frag', hcpcs: '25609' },
  { id: 2624, description: 'Anesth pelvic tumor surgery', hcpcs: '01150' },
  { id: 2625, description: 'Induced abortion 17-24 weeks', hcpcs: 'S2260' },
  { id: 2626, description: 'Correction eyelid w/implant', hcpcs: '67912' },
  { id: 2627, description: 'Partial removal of colon', hcpcs: '44144' },
  { id: 2628, description: 'Partial removal of tibia', hcpcs: '27640' },
  { id: 2629, description: 'Intrmed rsk thromboembolism', hcpcs: '3551F' },
  { id: 13795, description: 'Cabg vein five', hcpcs: '33514' },
  { id: 2630, description: 'Office o/p est low 20-29 min', hcpcs: '99213' },
  { id: 2631, description: 'Ablate heart dysrhythm focus', hcpcs: '33251' },
  { id: 2632, description: 'Red cell sequestration', hcpcs: '78140' },
  { id: 2633, description: 'Mod or severe osa', hcpcs: 'G8846' },
  { id: 2634, description: 'Repair of urethra defect', hcpcs: '53520' },
  { id: 2635, description: 'Tubular dressing', hcpcs: 'A6457' },
  { id: 2636, description: 'Rasburicase', hcpcs: 'J2783' },
  { id: 2637, description: 'Hemin, 1 mg', hcpcs: 'J1640' },
  { id: 2638, description: 'Immunohisto antb 1st stain', hcpcs: '88342' },
  { id: 2639, description: 'Spiro no perf or doc', hcpcs: 'G8926' },
  { id: 2640, description: 'Resect apical lung tum/chest', hcpcs: '32504' },
  { id: 2641, description: 'Dialysis one evaluation', hcpcs: '90945' },
  { id: 2642, description: 'Repair bowel opening', hcpcs: '44625' },
  { id: 2643, description: 'Amputate leg at thigh', hcpcs: '27590' },
  { id: 2644, description: 'Hydrogel drg >48 in w/o bdr', hcpcs: 'A6244' },
  { id: 2645, description: 'Contour of face bone lesion', hcpcs: '21029' },
  { id: 2646, description: 'Antiemetic drug oral nos', hcpcs: 'J8597' },
  { id: 2647, description: 'Exc face-mm b9+marg 0.5 cm/<', hcpcs: '11440' },
  { id: 2648, description: 'Mammo result com to pt 5 day', hcpcs: '5062F' },
  { id: 2649, description: 'Insert 2 electrode pm-defib', hcpcs: '33217' },
  { id: 2650, description: 'Endo cholangiopancreatograph', hcpcs: '43261' },
  { id: 2651, description: 'General anesth ea addl 15 mi', hcpcs: 'D9223' },
  { id: 2652, description: 'Rem endovas vena cava filter', hcpcs: '37193' },
  { id: 2653, description: 'Electrophysiology evaluation', hcpcs: '93622' },
  { id: 2654, description: 'Bhv id suprt assmt by 1 tech', hcpcs: '97152' },
  { id: 2655, description: 'Blood/lymph system procedure', hcpcs: '38999' },
  { id: 2656, description: 'Assay of protein urine', hcpcs: '84156' },
  { id: 2657, description: 'Smokeless tobacco user', hcpcs: '1035F' },
  { id: 2658, description: 'Measure blood oxygen level', hcpcs: '94761' },
  { id: 2659, description: 'Revision of knee joint', hcpcs: '27445' },
  { id: 2660, description: 'Repair/graft of thigh muscle', hcpcs: '27386' },
  { id: 2661, description: 'Hepatitis b surface ag ia', hcpcs: '87340' },
  { id: 2662, description: 'Anesth amputation at pelvis', hcpcs: '01140' },
  { id: 2663, description: 'Injection into brain canal', hcpcs: '61026' },
  { id: 2664, description: 'Incision of tendon & muscle', hcpcs: '23405' },
  { id: 2665, description: 'Incision of heel bone', hcpcs: '28300' },
  { id: 2666, description: 'Als esophageal intub suppls', hcpcs: 'A0396' },
  { id: 2667, description: 'Tis trnfr trunk 10 sq cm/<', hcpcs: '14000' },
  { id: 2668, description: 'Laryngoscop w/arytenoidectom', hcpcs: '31560' },
  { id: 2669, description: 'Sleeve, inter limb comp dev', hcpcs: 'A4600' },
  { id: 2670, description: 'Audiometry air only', hcpcs: '0208T' },
  { id: 2671, description: 'Removal of gallbladder', hcpcs: '47612' },
  { id: 2672, description: 'Treatment of miscarriage', hcpcs: '59812' },
  { id: 2673, description: 'Assay phosphatidylglycerol', hcpcs: '84081' },
  { id: 2674, description: 'Nerve graft hand/foot </4 cm', hcpcs: '64895' },
  { id: 2675, description: 'Start eoc doc med rec', hcpcs: 'M1111' },
  { id: 2676, description: 'Suicide risk assessed', hcpcs: '3085F' },
  { id: 2677, description: 'Incision of eyelid', hcpcs: '67710' },
  { id: 2678, description: 'Lens sphcy bifocal 4.25-7/2.', hcpcs: 'V2208' },
  { id: 2679, description: 'Hysteroscopy biopsy', hcpcs: '58558' },
  { id: 2680, description: 'Liver elastography', hcpcs: '91200' },
  { id: 2681, description: 'Remove wrist joint implant', hcpcs: '25449' },
  { id: 2682, description: 'Art byp grft hepatorenal', hcpcs: '35535' },
  { id: 2683, description: 'Pcv13 vaccine im', hcpcs: '90670' },
  { id: 2684, description: 'Rx ace or arb therapy', hcpcs: 'G8935' },
  { id: 2685, description: 'Repair septal defect', hcpcs: '33813' },
  { id: 2686, description: 'Insert heyman uteri capsule', hcpcs: '58346' },
  { id: 2687, description: 'Inj testosterone cypionate', hcpcs: 'J1071' },
  { id: 2688, description: 'Tc99m medronate', hcpcs: 'A9503' },
  { id: 2689, description: 'Fuse hand bones with graft', hcpcs: '25825' },
  { id: 2690, description: 'Partial removal of foot bone', hcpcs: '28122' },
  { id: 2691, description: 'Laparoscopy splenectomy', hcpcs: '38120' },
  { id: 2692, description: 'Resect/excise cranial lesion', hcpcs: '61608' },
  { id: 2693, description: 'Brain aneurysm repr simple', hcpcs: '61700' },
  { id: 2694, description: 'Closed tx nose/jaw fx', hcpcs: '21345' },
  { id: 2695, description: 'Other tmj images by report', hcpcs: 'D0321' },
  { id: 2696, description: 'Treat pleurodesis w/agent', hcpcs: '32560' },
  { id: 2697, description: 'Treat hip wall fracture', hcpcs: '27226' },
  { id: 2698, description: 'Knee arthroscopy/surgery', hcpcs: '29889' },
  { id: 2699, description: 'Remove lamina/facets lumbar', hcpcs: '63012' },
  { id: 2700, description: 'Psycl/nrpsyc tst phy/qhp 1st', hcpcs: '96136' },
  { id: 2701, description: 'Ct procedure', hcpcs: '76497' },
  { id: 2702, description: 'A/d tx program, per diem', hcpcs: 'H2036' },
  { id: 2703, description: 'Home infusion/visit 2 hrs', hcpcs: '99601' },
  { id: 2704, description: 'Asthma impairment assessed', hcpcs: '2015F' },
  { id: 2705, description: 'Biopsy/removal lymph nodes', hcpcs: '38520' },
  { id: 2706, description: 'Work hardening add-on', hcpcs: '97546' },
  { id: 2707, description: 'Off base opioid tx, 60 m', hcpcs: 'G2087' },
  { id: 2708, description: 'Em session reporting', hcpcs: 'G9891' },
  { id: 2709, description: 'Onc dx nhl stg unknown', hcpcs: 'G9138' },
  { id: 2710, description: 'Percut allergy skin tests', hcpcs: '95004' },
  { id: 2711, description: 'Vein x-ray kidneys', hcpcs: '75833' },
  { id: 2712, description: 'Start eoc doc med rec', hcpcs: 'M1106' },
  { id: 2713, description: 'Treat humerus fracture', hcpcs: '24579' },
  { id: 2714, description: 'Pierce skull & explore', hcpcs: '61250' },
  { id: 2715, description: 'Bridge resin base metal', hcpcs: 'D6251' },
  { id: 2716, description: 'Cmbn ap colprhy w/ntrcl rpr', hcpcs: '57265' },
  { id: 2717, description: 'Ercp remove duct calculi', hcpcs: '43264' },
  { id: 2718, description: 'Fixed prosthodontic proc', hcpcs: 'D6999' },
  { id: 2719, description: 'Hepatobil syst image w/drug', hcpcs: '78227' },
  { id: 2720, description: 'Card tamp w/in 30d', hcpcs: 'G9408' },
  { id: 2721, description: 'Lens polycarb or equal', hcpcs: 'V2784' },
  { id: 2722, description: 'Diabetic management program,', hcpcs: 'S9455' },
  { id: 2723, description: 'Disability examination', hcpcs: '99456' },
  { id: 2724, description: 'Clofarabine injection', hcpcs: 'J9027' },
  { id: 2725, description: 'Lung function test (mbc/mvv)', hcpcs: '94200' },
  { id: 2726, description: 'Car ion chnnlpath inc 2 gns', hcpcs: '81414' },
  { id: 2727, description: 'Furosemide injection', hcpcs: 'J1940' },
  { id: 2728, description: 'Mpm used', hcpcs: 'G2148' },
  { id: 2729, description: 'Egd remove lesion snare', hcpcs: '43251' },
  { id: 2730, description: 'Other specified case mgmt', hcpcs: 'G9012' },
  { id: 2731, description: 'Strapping of knee', hcpcs: '29530' },
  { id: 2732, description: 'Removal of larynx & pharynx', hcpcs: '31390' },
  { id: 2733, description: 'Genet virus isolate hsv', hcpcs: '87255' },
  { id: 2734, description: 'Revise eye muscle', hcpcs: '67311' },
  { id: 2735, description: 'Umbilical artery echo', hcpcs: '76820' },
  { id: 2736, description: 'Chemo intralesional over 7', hcpcs: '96406' },
  { id: 2737, description: 'Alginate dressing > 48 sq in', hcpcs: 'A6198' },
  { id: 2738, description: 'Repair of vagina', hcpcs: '56800' },
  { id: 2739, description: 'Drugs/meds disp for home use', hcpcs: 'D9630' },
  { id: 2740, description: 'Excise excessive skin buttck', hcpcs: '15835' },
  { id: 2741, description: 'Endoscopy of ureter', hcpcs: '50951' },
  { id: 2742, description: 'Remove uterus lesion', hcpcs: '59100' },
  { id: 2743, description: 'Tiss exam molecular study', hcpcs: '88387' },
  { id: 2744, description: 'Provisional pontic', hcpcs: 'D6253' },
  { id: 2745, description: 'Cast sup long arm ped fbrgls', hcpcs: 'Q4008' },
  { id: 2746, description: 'Co/membane diffuse capacity', hcpcs: '94729' },
  { id: 2747, description: 'Perip nerve block', hcpcs: 'G9770' },
  { id: 2748, description: 'Overdenture complete mandib', hcpcs: 'D5865' },
  { id: 2749, description: 'Cmv antibody igm', hcpcs: '86645' },
  { id: 2750, description: 'Rev, comm, track, doc biopsy', hcpcs: 'G8883' },
  { id: 2751, description: 'Denture interm part maxill', hcpcs: 'D5820' },
  { id: 2752, description: 'Pt not disch home day#2 evar', hcpcs: 'G8833' },
  { id: 2753, description: 'Forensic cytopathology', hcpcs: '88125' },
  { id: 2754, description: 'Antigen therapy services', hcpcs: '95144' },
  { id: 2755, description: 'Removal of heart lesion', hcpcs: '33130' },
  { id: 2756, description: 'No card tamp e/in 30d', hcpcs: 'G9409' },
  { id: 2757, description: 'Ecmo/ecls repos perph cnula', hcpcs: '33963' },
  { id: 2758, description: 'Treat finger fracture each', hcpcs: '26735' },
  { id: 2759, description: 'Unlisted procedure colon', hcpcs: '45399' },
  { id: 2760, description: 'Home visit nb care', hcpcs: '99502' },
  { id: 2761, description: 'Cardiovascular nuclear exam', hcpcs: '78499' },
  { id: 2762, description: 'Muscle biopsy', hcpcs: '20200' },
  { id: 2763, description: 'Hspc pt prv time meam per', hcpcs: 'M1067' },
  { id: 2764, description: 'Repair esophagus and fistula', hcpcs: '43312' },
  { id: 2765, description: 'Manipulate wrist w/anesthes', hcpcs: '25259' },
  { id: 2766, description: 'Nuclear rx intracav admin', hcpcs: '79200' },
  { id: 2767, description: 'Anesth larynx/trach < 1 yr', hcpcs: '00326' },
  { id: 2768, description: 'Hysterectomy/revise vagina', hcpcs: '58280' },
  { id: 2769, description: 'Meas lung vol thru 2 yrs', hcpcs: '94013' },
  { id: 2770, description: 'Pulm ds ipf mrna 190 gen alg', hcpcs: '81554' },
  { id: 2771, description: 'Exploration of kidney', hcpcs: '50045' },
  { id: 2772, description: 'Brain aneurysm repr complx', hcpcs: '61697' },
  { id: 2773, description: 'Doc med rsn no srn tb', hcpcs: 'M1004' },
  { id: 2774, description: 'Remove impacted ear wax uni', hcpcs: '69210' },
  { id: 2775, description: 'Bicarbonate conc pow per pac', hcpcs: 'A4707' },
  { id: 2776, description: 'Explr/decompress eye socket', hcpcs: '67414' },
  { id: 2777, description: 'Trabeculostomy interno laser', hcpcs: '0621T' },
  { id: 2778, description: 'Expl n/flwd surg neck art', hcpcs: '35701' },
  { id: 2779, description: 'Ikbkap gene', hcpcs: '81260' },
  { id: 2780, description: 'Hydrocolld drg <=16 in w/bdr', hcpcs: 'A6237' },
  { id: 2781, description: 'Hospice care supervision', hcpcs: '99378' },
  { id: 2782, description: 'Cabg vein three', hcpcs: '33512' },
  { id: 2783, description: 'Urine reagent strips/tablets', hcpcs: 'A4250' },
  { id: 2784, description: 'Cystoscopy and treatment', hcpcs: '52235' },
  { id: 2785, description: 'Mri abdomen w/o dye', hcpcs: '74181' },
  { id: 2786, description: 'Extensive surgery of throat', hcpcs: '42842' },
  { id: 2787, description: 'Care coord at hospice', hcpcs: 'G9477' },
  { id: 2788, description: 'Onc dx rectal t1-2 no progr', hcpcs: 'G9090' },
  { id: 2789, description: 'Dermapure 1 square cm', hcpcs: 'Q4152' },
  { id: 2790, description: 'Tc99m oxidronate', hcpcs: 'A9561' },
  { id: 2791, description: 'Doc pos elder mal scrn plan', hcpcs: 'G8733' },
  { id: 2792, description: 'Removal of humerus lesion', hcpcs: '23156' },
  { id: 2793, description: 'Remove/graft bone lesion', hcpcs: '24115' },
  { id: 2794, description: 'Russell viper venom diluted', hcpcs: '85613' },
  { id: 2795, description: 'Us trgt dyn mbubb 1st les', hcpcs: '76978' },
  { id: 2796, description: 'Radiation treatment delivery', hcpcs: '77401' },
  { id: 2797, description: 'Plmt post facet implt lumb', hcpcs: '0221T' },
  { id: 2798, description: 'Endo assay seven anal', hcpcs: '81506' },
  { id: 2799, description: 'No signs melanoma', hcpcs: 'G8749' },
  { id: 2800, description: 'In vivo lab service', hcpcs: '88749' },
  { id: 2801, description: 'Release of midfoot joint', hcpcs: '28260' },
  { id: 2802, description: 'Incise/drain tear gland', hcpcs: '68400' },
  { id: 2803, description: 'Pt 66+ lt ints > 90', hcpcs: 'G2106' },
  { id: 2804, description: 'Incision of eyelid fold', hcpcs: '67715' },
  { id: 2805, description: 'Replace tunneled cv cath', hcpcs: '36581' },
  { id: 2806, description: 'No doc share dec prior proc', hcpcs: 'G9297' },
  { id: 2807, description: 'Incis spine 3 column adl seg', hcpcs: '22208' },
  { id: 2808, description: 'Pt ref app rsrcs', hcpcs: 'G2186' },
  { id: 2809, description: 'I&d abscess extraoral', hcpcs: 'D7520' },
  { id: 2810, description: 'Repair hand joint with graft', hcpcs: '26542' },
  { id: 2811, description: 'Removal/revision of cast', hcpcs: '29710' },
  { id: 2812, description: 'Mri pelvis w/o & w/dye', hcpcs: '72197' },
  { id: 2813, description: 'Special supply, nos waiver', hcpcs: 'T2028' },
  { id: 2814, description: 'Remove knee joint lining', hcpcs: '27334' },
  { id: 2815, description: 'Art byp subclav-axillary', hcpcs: '35616' },
  { id: 2816, description: 'Extensive prostate surgery', hcpcs: '55815' },
  { id: 2817, description: 'Cast sup sht arm splnt ped p', hcpcs: 'Q4023' },
  { id: 2818, description: 'Sacituzumab govitecan-hziy', hcpcs: 'J9317' },
  { id: 2819, description: 'Loudness balance test', hcpcs: '92562' },
  { id: 2820, description: 'C-reactive protein hs', hcpcs: '86141' },
  { id: 2821, description: 'Uppr gi scope w/submuc inj', hcpcs: '43236' },
  { id: 2822, description: 'Ecmo/ecls repos perph cnula', hcpcs: '33957' },
  { id: 2823, description: 'Golimumab for iv use 1mg', hcpcs: 'J1602' },
  { id: 2824, description: 'Repair vessel defect', hcpcs: '33802' },
  { id: 2825, description: 'Cysto/uretero w/lithotripsy', hcpcs: '52356' },
  { id: 2826, description: 'Lap rmvl gastr adj all parts', hcpcs: '43774' },
  { id: 2827, description: 'Cystatin c', hcpcs: '82610' },
  { id: 2828, description: 'Radiopharm rx agent noc', hcpcs: 'A9699' },
  { id: 2829, description: 'Blood clot retraction', hcpcs: '85170' },
  { id: 2830, description: 'Treat knuckle dislocation', hcpcs: '26715' },
  { id: 2831, description: 'Harvest auto stem cells', hcpcs: '38206' },
  { id: 2832, description: 'Interdental fixation', hcpcs: '21110' },
  { id: 2833, description: 'Mechanical traction therapy', hcpcs: '97012' },
  { id: 2834, description: 'Rem interrog dev icds tech', hcpcs: '0579T' },
  { id: 2835, description: 'Antibody test pub hlth path', hcpcs: 'D0605' },
  { id: 2836, description: 'Bl draw <3 yrs other vein', hcpcs: '36406' },
  { id: 2837, description: 'Removal of eye lesion', hcpcs: '65400' },
  { id: 2838, description: 'Revise thumb tendon', hcpcs: '26490' },
  { id: 2839, description: 'Elec stim other than wound', hcpcs: 'G0283' },
  { id: 2840, description: 'Bio mtrls to aid soft/os reg', hcpcs: 'D4265' },
  { id: 2841, description: 'Surg rep root res molar', hcpcs: 'D3473' },
  { id: 2842, description: 'Dna analysis deafness', hcpcs: 'S3844' },
  { id: 2843, description: 'Intragastric hypothermia', hcpcs: 'M0100' },
  { id: 2844, description: 'Private duty/independent nsg', hcpcs: 'T1000' },
  { id: 2845, description: 'Upper gi endo shows barrtts', hcpcs: '3140F' },
  { id: 2846, description: 'Prefab porc/ceram crown pri', hcpcs: 'D2929' },
  { id: 2847, description: 'Sterile water/saline, 500 ml', hcpcs: 'A4217' },
  { id: 2848, description: 'Exploratory heart surgery', hcpcs: '33310' },
  { id: 2849, description: 'Repair nerve add-on', hcpcs: '64832' },
  { id: 2850, description: 'Acoustic immitance testing', hcpcs: '92570' },
  { id: 2851, description: 'Removal kidney open radical', hcpcs: '50230' },
  { id: 2852, description: 'X-rays bone survey complete', hcpcs: '77075' },
  { id: 2853, description: 'Psycl/nrpsyc tst tech ea', hcpcs: '96139' },
  { id: 2854, description: 'Assay of chloramphenicol', hcpcs: '82415' },
  { id: 2855, description: 'Mammo assess negative docd', hcpcs: '3341F' },
  { id: 2856, description: 'Artery-vein nonautograft', hcpcs: '36830' },
  { id: 2857, description: 'Culture bacteria anaerobic', hcpcs: '87073' },
  { id: 2858, description: 'Ost pch urine w lock flng/ft', hcpcs: 'A4434' },
  { id: 2859, description: 'Cd4+cell cnt/% docd as done', hcpcs: '3500F' },
  { id: 2860, description: 'Gene test alpha-thalassemia', hcpcs: 'S3845' },
  { id: 2861, description: 'Limit oral eval problm focus', hcpcs: 'D0140' },
  { id: 2862, description: 'Levalbuterol comp con', hcpcs: 'J7607' },
  { id: 2863, description: 'Xray control catheter change', hcpcs: '75984' },
  { id: 2864, description: 'Assay of urine osmolality', hcpcs: '83935' },
  { id: 2865, description: 'Repair of skull defect', hcpcs: '62141' },
  { id: 2866, description: 'Removal of arm bone lesion', hcpcs: '24134' },
  { id: 2867, description: 'Sc ther infusion reset pump', hcpcs: '96371' },
  { id: 2868, description: 'Strapping of toes', hcpcs: '29550' },
  { id: 2869, description: 'No rx ace/arb therapy', hcpcs: 'G8937' },
  { id: 2870, description: 'Revise major vessel', hcpcs: '33824' },
  { id: 2871, description: 'Pvdr rfrd pt no rprt rcvd', hcpcs: 'G9970' },
  { id: 2872, description: 'Initial visit for episode', hcpcs: '0525F' },
  { id: 2873, description: 'Donor lobectomy (lung)', hcpcs: 'S2061' },
  { id: 2874, description: 'Thromboxane urine', hcpcs: '84431' },
  { id: 2875, description: 'Surgical obturator', hcpcs: 'D5931' },
  { id: 2876, description: 'Resect/excise cranial lesion', hcpcs: '61601' },
  { id: 2877, description: 'Open aortic tube prosth repr', hcpcs: '34830' },
  { id: 2878, description: 'Revision of neck muscle', hcpcs: '21725' },
  { id: 2879, description: 'Plasmaprotein fract,5%,250ml', hcpcs: 'P9048' },
  { id: 2880, description: 'Alprostadil urethral suppos', hcpcs: 'J0275' },
  { id: 2881, description: 'Cmplx rpr f/c/c/m/n/ax/g/h/f', hcpcs: '13133' },
  { id: 2882, description: 'Hdr rdncl ntrstl/icav brchtx', hcpcs: '77770' },
  { id: 2883, description: 'Anesth trach-bronch reconst', hcpcs: '00539' },
  { id: 2884, description: 'Treat lower jaw fracture', hcpcs: '21450' },
  { id: 2885, description: 'Biphos thxpy vein ord/recvd', hcpcs: '4100F' },
  { id: 2886, description: 'Bkbench rcnstj don uter artl', hcpcs: '0670T' },
  { id: 2887, description: 'Colpopexy intraperitoneal', hcpcs: '57283' },
  { id: 2888, description: 'Inj. romosozumab-aqqg 1 mg', hcpcs: 'J3111' },
  { id: 2889, description: 'Drainage of pilonidal cyst', hcpcs: '10080' },
  { id: 2890, description: 'Pouch drainable w barrier at', hcpcs: 'A5061' },
  { id: 2891, description: 'Remvl aortic ventr cmpl sys', hcpcs: '0455T' },
  { id: 2892, description: 'Dx bone marrow biopsies', hcpcs: '38221' },
  { id: 2893, description: 'Fracture assessment via dxa', hcpcs: '77086' },
  { id: 2894, description: 'Treat skull fracture', hcpcs: '62005' },
  { id: 2895, description: 'Incise & treat bladder', hcpcs: '51030' },
  { id: 2896, description: 'Removal of kidney & ureter', hcpcs: '50236' },
  { id: 2897, description: 'Dental coronoidectomy', hcpcs: 'D7991' },
  { id: 2898, description: 'Treat elbow fracture', hcpcs: '24635' },
  { id: 2899, description: 'Onc dx ovarian stg1a-b no pr', hcpcs: 'G9113' },
  { id: 2900, description: 'Remove abdominal lymph nodes', hcpcs: '38747' },
  { id: 2901, description: 'Bl smear w/o diff wbc count', hcpcs: '85008' },
  { id: 2902, description: 'Excision of skull/sutures', hcpcs: '61559' },
  { id: 2903, description: 'Nasal function studies', hcpcs: '92512' },
  { id: 2904, description: 'Ongoing care not ind', hcpcs: 'M1146' },
  { id: 2905, description: 'Methylprednisolone 20 mg inj', hcpcs: 'J1020' },
  { id: 2906, description: 'Implant neuroelectrodes', hcpcs: '64580' },
  { id: 2907, description: 'Medrsn no pt category', hcpcs: 'G9292' },
  { id: 2908, description: 'Release palm contracture', hcpcs: '26121' },
  { id: 2909, description: 'Lsh w/t/o ut 250 g or less', hcpcs: '58542' },
  { id: 2910, description: 'Infiltration thera drug', hcpcs: 'D9613' },
  { id: 2911, description: 'Octreotide inj, non-depot', hcpcs: 'J2354' },
  { id: 2912, description: 'Removal of thyroid', hcpcs: '60240' },
  { id: 2913, description: 'Open bone biopsy', hcpcs: '20251' },
  { id: 2914, description: 'Mecasermin injection', hcpcs: 'J2170' },
  { id: 2915, description: 'Remove impacted ear wax uni', hcpcs: '69209' },
  { id: 2916, description: 'Ntrapx c ffr w/3d funcjl map', hcpcs: '0523T' },
  { id: 2917, description: 'Abrasion lesion single', hcpcs: '15786' },
  { id: 2918, description: 'Revise/graft eyelid lining', hcpcs: '68325' },
  { id: 2919, description: 'Anesth vaginal endoscopy', hcpcs: '00950' },
  { id: 2920, description: 'Tis trnfr addl 30 sq cm', hcpcs: '14302' },
  { id: 2921, description: 'Colonoscopy w/resection', hcpcs: '44403' },
  { id: 2922, description: 'Ost pch for bar w flange/flt', hcpcs: 'A4419' },
  { id: 2923, description: 'Vert bitewings 7 to 8 images', hcpcs: 'D0277' },
  { id: 2924, description: 'Revision of big toe', hcpcs: '28310' },
  { id: 2925, description: 'Head surgery procedure', hcpcs: '21499' },
  { id: 2926, description: 'Evasc prlng admn rx agnt add', hcpcs: '61651' },
  { id: 2927, description: 'Exc h-f-nk-sp b9+marg 0.5/<', hcpcs: '11420' },
  { id: 2928, description: 'Ashkenazi jewish assoc dis', hcpcs: '81412' },
  { id: 2929, description: 'Iodine i-131 iobenguane 1mci', hcpcs: 'A9590' },
  { id: 2930, description: 'Incision of metatarsal', hcpcs: '28306' },
  { id: 2931, description: 'Bronchoscopy treat blockage', hcpcs: '31641' },
  { id: 2932, description: 'Anesth chest drainage', hcpcs: '00524' },
  { id: 2933, description: 'Ecmo/ecls repos perph cnula', hcpcs: '33964' },
  { id: 2934, description: 'Ed svc ckd grp per session', hcpcs: 'G0421' },
  { id: 2935, description: 'Endo abl proc yr prev ind dt', hcpcs: 'G9822' },
  { id: 2936, description: 'Incise skull/brain surgery', hcpcs: '61735' },
  { id: 2937, description: 'Gadobutrol injection', hcpcs: 'A9585' },
  { id: 2938, description: 'Revise spinal cord vsls crvl', hcpcs: '63250' },
  { id: 2939, description: 'Fibrinogen activity', hcpcs: '85384' },
  { id: 2940, description: 'Brnchsc w/ther aspir sbsq', hcpcs: '31646' },
  { id: 2941, description: 'Novafix dl per sq cm', hcpcs: 'Q4254' },
  { id: 2942, description: 'Ligation upper jaw artery', hcpcs: '30920' },
  { id: 2943, description: 'Adult daycare services 15min', hcpcs: 'S5100' },
  { id: 2944, description: 'Neuromuscular reeducation', hcpcs: '97112' },
  { id: 2945, description: 'Revise eyelid lining', hcpcs: '68330' },
  { id: 2946, description: 'X-ray xm upr gi trc 2cntrst', hcpcs: '74246' },
  { id: 2947, description: 'Er and ip hosp <2 in 12 mos', hcpcs: 'G9521' },
  { id: 2948, description: 'Immunology procedure', hcpcs: '86849' },
  { id: 2949, description: 'Family psytx w/pt 50 min', hcpcs: '90847' },
  { id: 2950, description: 'Anesth/analg vag delivery', hcpcs: '01967' },
  { id: 2951, description: 'Xylose tolerance test', hcpcs: '84620' },
  { id: 2952, description: 'Bone replce graft first site', hcpcs: 'D4263' },
  { id: 2953, description: 'Drainage of finger abscess', hcpcs: '26010' },
  { id: 2954, description: 'Minoxidil, 10 mg', hcpcs: 'S0139' },
  { id: 2955, description: 'Telethx isodose plan cplx', hcpcs: '77307' },
  { id: 2956, description: 'Stat lab', hcpcs: 'S3600' },
  { id: 2957, description: 'Chemo by both infusion and o', hcpcs: 'Q0085' },
  { id: 2958, description: 'Rem unilat dent 1 pc resin', hcpcs: 'D5286' },
  { id: 2959, description: 'Fusion of pubic bones', hcpcs: '27282' },
  { id: 2960, description: 'Repair of foot bones', hcpcs: '28320' },
  { id: 2961, description: 'Lenticular lens, bifocal', hcpcs: 'V2221' },
  { id: 2962, description: 'Treatment of tibia fracture', hcpcs: '27750' },
  { id: 2963, description: 'Anesth burn 4-9 percent', hcpcs: '01952' },
  { id: 2964, description: 'Puncture/clear windpipe', hcpcs: '31612' },
  { id: 2965, description: 'Nursing fac care subseq', hcpcs: '99307' },
  { id: 2966, description: 'Brush biopsy', hcpcs: 'D7288' },
  { id: 2967, description: 'Intraut copper contraceptive', hcpcs: 'J7300' },
  { id: 2968, description: 'Prgrmg eval cardiac modulj', hcpcs: '0417T' },
  { id: 2969, description: 'Pre-prxd rsk et al docd', hcpcs: '3018F' },
  { id: 2970, description: 'Removal of salivary stone', hcpcs: '42335' },
  { id: 2971, description: 'Assay of tobramycin', hcpcs: '80200' },
  { id: 2972, description: 'Esophageal recording', hcpcs: '93615' },
  { id: 2973, description: 'Mlh1 gene known variants', hcpcs: '81293' },
  { id: 2974, description: 'Assay of glucagon', hcpcs: '82943' },
  { id: 2975, description: 'Revision of heart chamber', hcpcs: '33476' },
  { id: 2976, description: 'Cultured chondrocytes implnt', hcpcs: 'J7330' },
  { id: 2977, description: 'Mitomycin instillation', hcpcs: 'J9281' },
  { id: 2978, description: 'Drainage of hip joint', hcpcs: '27030' },
  { id: 2979, description: 'Cytogenetics dna probe', hcpcs: '88271' },
  { id: 2980, description: 'Fs no odi 9-15mo', hcpcs: 'M1043' },
  { id: 2981, description: 'Thorax stereo rad targetw/tx', hcpcs: '32701' },
  { id: 2982, description: 'Endo cholangiopancreatograph', hcpcs: '43262' },
  { id: 2983, description: 'Ostomy face plate', hcpcs: 'A4361' },
  { id: 2984, description: 'Anesthesia removal pleura', hcpcs: '00542' },
  { id: 2985, description: 'Respite not-in-home per diem', hcpcs: 'H0045' },
  { id: 2986, description: 'Pt 50 yrs w/clin ind hd', hcpcs: 'G2188' },
  { id: 2987, description: 'Hyoscyamine sulfate inj', hcpcs: 'J1980' },
  { id: 2988, description: 'Ped compr care pkg, per hour', hcpcs: 'T1026' },
  { id: 2989, description: 'Heart flow reserve measure', hcpcs: '93572' },
  { id: 2990, description: 'Transcochlear approach/skull', hcpcs: '61596' },
  { id: 2991, description: 'Dvt prophylx recvd day 2', hcpcs: '4070F' },
  { id: 2992, description: 'Cgh test developmental delay', hcpcs: 'S3870' },
  { id: 2993, description: 'Peri medicament w/seal, max', hcpcs: 'D5995' },
  { id: 2994, description: 'Hlth bhv ivntj indiv ea addl', hcpcs: '96159' },
  { id: 2995, description: 'Cyp2c19 gene com variants', hcpcs: '81225' },
  { id: 2996, description: 'X-ray exam abdomen 3+ views', hcpcs: '74021' },
  { id: 2997, description: 'Removal of brain tissue', hcpcs: '61539' },
  { id: 2998, description: 'Largsc w/ther injection', hcpcs: '31573' },
  { id: 2999, description: 'Decompressive lobectomy', hcpcs: '61323' },
].map(s => ({
  ...s,
  prices: [...Array(30)].map(generatePrice),
}))

export default services
