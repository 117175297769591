import regions from './regions'

const providers = [
  {
    name: 'Accu-Med Diagnostic Center',
    url: '',
    address: {
      id: 1,
      city: 'Miami',
      state: 'FL',
      state_full: 'Florida',
      zip: '33169',
    },
    region: { id: 10, code: 'FL-Miami' },
    type: { id: 2, type: 'Primary Care' },
  },
  {
    name: 'Fake Provider',
    url: '',
    address: {
      id: 2,
      city: 'Jackson',
      state: 'MS',
      state_full: 'Mississippi',
      zip: '39201',
    },
    region: { id: 2, code: 'MS-Jackson' },
    type: { id: 1, type: 'Hospital' },
  },
  {
    name: 'Visions Physical Therapy',
    url: '',
    address: {
      id: 3,
      city: 'Flowood',
      state: 'MS',
      state_full: 'Mississippi',
      zip: '39232',
    },
    region: { id: 2, code: 'MS-Jackson' },
    type: { id: 3, type: 'Specialist' },
  },
  {
    name: 'Elite Physical Therapy',
    url: '',
    address: {
      id: 4,
      city: 'Flowood/Brandon/Ridgeland',
      state: 'MS',
      state_full: 'Mississippi',
      zip: '39232',
    },
    region: { id: 2, code: 'MS-Jackson' },
    type: { id: 3, type: 'Specialist' },
  },
  {
    name: 'Baptist Physical Therapy',
    url: '',
    address: {
      id: 5,
      city: 'Jackson',
      state: 'MS',
      state_full: 'Mississippi',
      zip: '39201',
    },
    region: { id: 2, code: 'MS-Jackson' },
    type: { id: 3, type: 'Specialist' },
  },
  {
    name: 'UMMC Physical Therapy',
    url: '',
    address: {
      id: 6,
      city: 'Jackson',
      state: 'MS',
      state_full: 'Mississippi',
      zip: '39201',
    },
    region: { id: 2, code: 'MS-Jackson' },
    type: { id: 3, type: 'Specialist' },
  },
]

export default regions
  .map(region => providers.map(p => ({ ...p, id: Math.random(), region })))
  .flat()
