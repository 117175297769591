import React, { useState } from 'react'
import Page from '../components/Page'
import {
  Stack,
  Card,
  Container,
  Typography,
  Button,
  Dialog,
  DialogContent,
} from '@mui/material'
import { Icon } from '@iconify/react'
import plusFill from '@iconify/icons-eva/plus-fill'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { FETCH_PROVIDER } from '../store/queries'
import camelize from '../utils/camelize'
import { fDate } from '../utils/formatTime'
import NewPriceForm from '../forms/NewPriceForm'

const columns = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'date',
    headerName: 'Date',
    flex: 0.2,
    valueFormatter: ({ value }) => fDate(value),
  },
  { field: 'hcpcs', headerName: 'CPT Code', flex: 0.2 },
  { field: 'serviceName', headerName: 'Service', flex: 0.4 },
  {
    field: 'cost',
    headerName: 'Price',
    flex: 0.2,
    valueFormatter: ({ value }) => '$' + value,
  },
]

const Provider = () => {
  const [isNewPriceFormOpen, setIsNewPriceFormOpen] = useState(false)
  const { providerId } = useParams()
  const { error, loading, data } = useQuery(FETCH_PROVIDER, {
    variables: { id: providerId },
  })
  if (error) return <div>Error</div>
  if (loading) return <div>Loading...</div>
  const provider = camelize(data).providers[0]
  provider.prices = provider.prices.map(p => ({
    ...p,
    serviceName: p.service.description,
    hcpcs: p.service.hcpcs,
  }))
  return (
    <Page title={`OCP - Provider ${provider.name}`}>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h3" gutterBottom>
            {provider.name}
          </Typography>
          <Button
            variant="contained"
            onClick={() => setIsNewPriceFormOpen(true)}
            startIcon={<Icon icon={plusFill} />}
          >
            New Price
          </Button>
        </Stack>
        <Dialog
          open={isNewPriceFormOpen}
          onClose={() => setIsNewPriceFormOpen(false)}
        >
          <DialogContent>
            <NewPriceForm
              initialProvider={provider}
              initialRegion={provider.region}
              onSubmit={() => setIsNewPriceFormOpen(false)}
            />
          </DialogContent>
        </Dialog>
        <Card>
          <DataGrid
            rows={provider.prices}
            columns={columns}
            components={{ GridToolbar }}
            pageSize={10}
            rowsPerPageOptions={[10]}
            autoHeight
          />
        </Card>
      </Container>
    </Page>
  )
}

export default Provider
