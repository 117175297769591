import {
  Box,
  TextField,
  Stack,
  Button,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
} from '@mui/material'
import React, { useState } from 'react'
import { Icon } from '@iconify/react'
import sharpSend from '@iconify/icons-ic/sharp-send'
import { useQuery, useMutation } from '@apollo/client'
import { FETCH_NEW_PROVIDER_FORM, FETCH_PROVIDERS } from '../store/queries'
import { INSERT_PROVIDER } from '../store/mutations'
import { US_STATES } from '../utils/constants'
import camelize from '../utils/camelize'
import snakeize from '../utils/snakeize'

const getIcon = name => <Icon icon={name} width={20} height={20} />

const stateOptions = US_STATES.map(s => ({
  label: s.name,
  value: s.abbreviation,
}))

const NewProviderForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    name: '',
    providerTypeId: '',
    type: '',
    regionId: '',
    city: '',
    state: '',
    zipCode: '',
    url: '',
  })

  const [insertProvider, { error: insertError, loading: insertLoading }] =
    useMutation(INSERT_PROVIDER, {
      onCompleted: resp => onSubmit(resp.insert_providers_one),
      refetchQueries: [FETCH_PROVIDERS],
    })
  const { data, loading, error } = useQuery(FETCH_NEW_PROVIDER_FORM)
  if (insertError) return <div>Error creating provider</div>
  if (error) return <div>Error</div>
  if (loading) return <div>Loading...</div>
  const { regions, providerTypes } = camelize(data)
  const regionOptions = regions.map(r => ({ label: r.code, value: r.id }))
  const providerTypeOptions = providerTypes.map(pt => ({
    label: pt.type,
    value: pt.id,
  }))
  const getChangeHandler = fieldName => e =>
    setFormData({ ...formData, [fieldName]: e.target.value })
  return (
    <Box
      component="form"
      p={3}
      onSubmit={e => {
        e.preventDefault()
        insertProvider({ variables: { data: snakeize(formData) } })
      }}
    >
      <Typography variant="h4" gutterBottom>
        New Provider
      </Typography>
      <Stack spacing={3}>
        <TextField
          value={formData.name}
          label="Name"
          onChange={getChangeHandler('name')}
        />
        <FormControl fullWidth>
          <InputLabel id="provider-type-select-label">Provider Type</InputLabel>
          <Select
            labelId="provider-type-select-label"
            id="provider-type-select"
            value={formData.providerTypeId}
            label="Provider Type"
            onChange={getChangeHandler('providerTypeId')}
          >
            {providerTypeOptions.map(o => (
              <MenuItem key={o.value} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="region-select-label">Region</InputLabel>
          <Select
            labelId="region-select-label"
            id="region-select"
            value={formData.regionId}
            label="Region"
            onChange={getChangeHandler('regionId')}
          >
            {regionOptions.map(o => (
              <MenuItem key={o.value} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          value={formData.city}
          onChange={getChangeHandler('city')}
          label="City"
        />
        <FormControl fullWidth>
          <InputLabel id="state-select-label">State</InputLabel>
          <Select
            labelId="state-select-label"
            id="state-select"
            value={formData.state}
            label="state"
            onChange={getChangeHandler('state')}
          >
            {stateOptions.map(o => (
              <MenuItem key={o.value} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          value={formData.zipCode}
          onChange={getChangeHandler('zipCode')}
          label="Zip Code"
        />
        <TextField
          value={formData.url}
          onChange={getChangeHandler('url')}
          label="Website"
        />
        <Button
          variant="contained"
          color="secondary"
          style={{ width: '125px' }}
          endIcon={getIcon(sharpSend)}
          type="submit"
          disabled={insertLoading}
        >
          SUBMIT
        </Button>
      </Stack>
    </Box>
  )
}

export default NewProviderForm
