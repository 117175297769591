import React from 'react'
import { Icon } from '@iconify/react'
import plusFill from '@iconify/icons-eva/plus-fill'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
// material
import { Card, Stack, Button, Container, Typography } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { observer } from 'mobx-react'
// components
import Page from '../components/Page'
import { fDate } from '../utils/formatTime'
import { FETCH_CASES } from '../store/queries'
import camelize from '../utils/camelize'

const columns = [
  { field: 'name', headerName: 'Name', flex: 0.2 },
  { field: 'regionCd', headerName: 'Region', flex: 0.2 },
  {
    field: 'dateCreated',
    headerName: 'Created',
    flex: 0.2,
    valueFormatter: ({ value }) => fDate(value),
  },
  // { field: 'dateModified', headerName: 'Modified', flex: 0.2 },
  { field: 'status', headerName: 'Status', flex: 0.2 },
  {
    field: 'dueDate',
    headerName: 'Due Date',
    flex: 0.2,
    valueFormatter: ({ value }) => fDate(value),
  },
]

const Cases = observer(({ store }) => {
  const navigate = useNavigate()
  const { loading, error, data } = useQuery(FETCH_CASES)
  if (error) return <div>Error</div>
  if (loading) return <div>Loading...</div>
  const cases = camelize(data).cases.map(c => ({
    ...c,
    regionCd: c.region?.code,
  }))
  return (
    <Page title="OCP - Cases">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h3" gutterBottom>
            Cases
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="new"
            startIcon={<Icon icon={plusFill} />}
          >
            New Case
          </Button>
        </Stack>

        <Card>
          <DataGrid
            rows={cases}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            autoHeight
            onRowDoubleClick={({ id }) => navigate('/cases/' + id)}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </Card>
      </Container>
    </Page>
  )
})

export default Cases
