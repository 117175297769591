import React, { useState } from 'react'
import { Icon } from '@iconify/react'
import plusFill from '@iconify/icons-eva/plus-fill'
import { useNavigate } from 'react-router-dom'
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Dialog,
  DialogContent,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useQuery } from '@apollo/client'
import camelize from '../utils/camelize'
// components
import Page from '../components/Page'
import NewProviderForm from '../forms/NewProviderForm'
import { FETCH_PROVIDERS } from '../store/queries'

const columns = [
  { field: 'name', headerName: 'Name', flex: 0.3 },
  { field: 'location', headerName: 'Location', flex: 0.3 },
  { field: 'regionCd', headerName: 'Region', flex: 0.2 },
  { field: 'typeCd', headerName: 'Type', flex: 0.2 },
]

const Providers = () => {
  const [isNewProviderFormOpen, setIsNewProviderFormOpen] = useState(false)
  const navigate = useNavigate()
  const { error, loading, data } = useQuery(FETCH_PROVIDERS)
  if (error) return <div>Error</div>
  if (loading) return <div>Loading...</div>
  const providers = camelize(data).providers.map(p => ({
    ...p,
    location: p.city && p.state ? p.city + ', ' + p.state : '',
    regionCd: p.region.code,
    typeCd: p.providerType.type,
  }))
  return (
    <Page title="OCP - Providers">
      <Dialog
        open={isNewProviderFormOpen}
        onClose={() => setIsNewProviderFormOpen(false)}
      >
        <DialogContent style={{ width: '600px' }}>
          <NewProviderForm
            onSubmit={newProvider => {
              setIsNewProviderFormOpen(false)
              navigate('/providers/' + newProvider.id)
            }}
          />
        </DialogContent>
      </Dialog>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h3" gutterBottom>
            Providers
          </Typography>
          <Button
            variant="contained"
            onClick={() => setIsNewProviderFormOpen(true)}
            startIcon={<Icon icon={plusFill} />}
          >
            New Provider
          </Button>
        </Stack>

        <Card>
          <DataGrid
            rows={providers}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            autoHeight
            onRowDoubleClick={({ id }) => navigate('/providers/' + id)}
          />
        </Card>
      </Container>
    </Page>
  )
}

export default Providers
