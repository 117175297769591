import { gql } from '@apollo/client'

const INSERT_PROVIDER = gql`
  mutation insertProvider($data: providers_insert_input!) {
    insert_providers_one(object: $data) {
      id
      name
    }
  }
`

const INSERT_PRICE = gql`
  mutation insertPrice($data: prices_insert_input!) {
    insert_prices_one(object: $data) {
      id
    }
  }
`

const INSERT_CASE = gql`
  mutation insertCase($data: cases_insert_input!) {
    insert_cases_one(object: $data) {
      id
    }
  }
`

const INSERT_CASE_SERVICES = gql`
  mutation insertCaseServices($data: [case_services_insert_input!]!) {
    insert_case_services(objects: $data) {
      returning {
        id
        case_id
      }
    }
  }
`

const DELETE_CASE_SERVICES = gql`
  mutation deleteCaseServices($id: Int!) {
    delete_case_services_by_pk(id: $id) {
      id
    }
  }
`

const INSERT_CASE_PROVIDER = gql`
  mutation insertCaseProvider($data: case_provider_insert_input!) {
    insert_case_provider(objects: [$data]) {
      returning {
        id
      }
    }
  }
`

const UPDATE_CASE_SERVICE_PRICES = gql`
  mutation updateCaseServicePrices(
    $case_service_id: Int!
    $data: [case_services_prices_insert_input!]!
  ) {
    delete_case_services_prices(
      where: { case_service_id: { _eq: $case_service_id } }
    ) {
      affected_rows
      returning {
        case_service_id
        id
        price_id
      }
    }
    insert_case_services_prices(objects: $data) {
      returning {
        id
        price_id
        case_service_id
      }
    }
  }
`

export {
  INSERT_PROVIDER,
  INSERT_PRICE,
  INSERT_CASE,
  INSERT_CASE_SERVICES,
  INSERT_CASE_PROVIDER,
  UPDATE_CASE_SERVICE_PRICES,
  DELETE_CASE_SERVICES,
}
