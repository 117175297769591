import { Icon } from '@iconify/react'
import briefcaseFill from '@iconify/icons-eva/briefcase-fill'
import creditCardFill from '@iconify/icons-eva/credit-card-fill'
import mapFill from '@iconify/icons-eva/map-fill'
import stethoscope from '@iconify/icons-tabler/stethoscope'
import hospital from '@iconify/icons-fa-solid/hospital'
// import organizationSolid from '@iconify/icons-clarity/organization-solid'

// ----------------------------------------------------------------------

const getIcon = name => <Icon icon={name} width={22} height={22} />

const sidebarConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: getIcon(pieChart2Fill),
  // },
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: getIcon(peopleFill),
  // },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon(shoppingBagFill),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon(fileTextFill),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill),
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill),
  // },
  {
    title: 'cases',
    path: '/cases',
    icon: getIcon(briefcaseFill),
  },
  {
    title: 'prices',
    path: '/prices',
    icon: getIcon(creditCardFill),
  },
  {
    title: 'regions',
    path: '/regions',
    icon: getIcon(mapFill),
  },
  {
    title: 'services',
    path: '/services',
    icon: getIcon(stethoscope),
  },
  {
    title: 'providers',
    path: '/providers',
    icon: getIcon(hospital),
  },
  // {
  //   title: 'provider types',
  //   path: '/provider_types',
  //   icon: getIcon(organizationSolid),
  // },
]

export default sidebarConfig
