const attachmentTypes = [
  'Architectural Renovations',
  'Attendant Care',
  'Future Routine Medical Care',
  'Orthotics Prosthetics',
  'Potential Complications',
  'Projected Therapeutic Modalities',
  'Supplies',
  'Surgical',
  'Transportation',
  'Wheel Chair Needs',
]

export default attachmentTypes
