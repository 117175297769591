import React, { useState, useMemo } from 'react'
// routes
import Router from './routes'
// theme
import ThemeConfig from './theme'
import GlobalStyles from './theme/globalStyles'
// components
import ScrollToTop from './components/ScrollToTop'
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'

import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'

// ----------------------------------------------------------------------

const App = () => {
  const { isLoading, isAuthenticated, loginWithRedirect, getIdTokenClaims } =
    useAuth0()
  const [token, setToken] = useState(null)
  const client = useMemo(
    () =>
      new ApolloClient({
        uri: 'https://capable-finch-79.hasura.app/v1/graphql',
        cache: new InMemoryCache(),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }),
    [token]
  )
  if (isLoading) return <div>Loading...</div>
  if (!isAuthenticated) return loginWithRedirect()
  getIdTokenClaims().then(c => setToken(c.__raw))
  if (!token) return <div>Loading...</div>
  return (
    <ApolloProvider client={client}>
      <ThemeConfig>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ScrollToTop />
          <GlobalStyles />
          <BaseOptionChartStyle />
          <Router />
        </LocalizationProvider>
      </ThemeConfig>
    </ApolloProvider>
  )
}

const AuthenticatedApp = () => (
  <Auth0Provider
    domain="ocp-bts.us.auth0.com"
    clientId="i2ffW94eDrszXOXfeRrlxejHYqIj7goM"
    redirectUri={window.location.origin}
    audience="hasura"
  >
    <App />
  </Auth0Provider>
)

export default AuthenticatedApp
