import { gql } from '@apollo/client'

export const FETCH_PROVIDERS = gql`
  query fetchProviders {
    providers {
      city
      state
      id
      name
      region {
        code
        id
      }
      provider_type {
        type
      }
    }
  }
`

export const FETCH_PROVIDER = gql`
  query fetchProvider($id: Int!) {
    providers(where: { id: { _eq: $id } }) {
      city
      state
      id
      name
      prices {
        id
        cost
        date
        service {
          description
          hcpcs
        }
      }
      region {
        code
        id
      }
      provider_type {
        type
      }
    }
  }
`

export const FETCH_CASES = gql`
  query fetchCases {
    cases {
      id
      name
      region {
        code
      }
      date_created
      due_date
      status
    }
  }
`
export const FETCH_PRICES = gql`
  query fetchPrices {
    prices {
      cost
      date
      documentation_source
      id
      provider {
        id
        name
        region {
          code
          id
        }
      }
      service {
        hcpcs
        id
        description
      }
    }
  }
`
export const FETCH_REGIONS = gql`
  query fetchRegions {
    regions {
      code
      id
    }
  }
`
export const FETCH_REGION = gql`
  query fetchRegion($id: Int!) {
    regions(where: { id: { _eq: $id } }) {
      code
      id
      providers {
        id
        name
        prices {
          id
          cost
          date
          service {
            hcpcs
            id
            description
          }
          provider {
            id
            name
          }
        }
      }
    }
  }
`

export const FETCH_SERVICES = gql`
  query fetchServices {
    services(limit: 100) {
      description
      hcpcs
      id
    }
  }
`

export const FETCH_SERVICE = gql`
  query fetchService($id: Int!) {
    services(where: { id: { _eq: $id } }) {
      description
      hcpcs
      id
      prices {
        cost
        date
        id
        provider {
          id
          name
          region {
            code
            id
          }
        }
      }
    }
  }
`

export const FETCH_CASE = gql`
  query getCase($id: Int!) {
    cases(where: { id: { _eq: $id } }) {
      id
      date_created
      due_date
      life_expectancy
      name
      region_id
      region {
        id
        code
      }
      status
      case_services {
        id
        case_services_prices {
          id
          price_id
          price {
            cost
            date
            id
          }
        }
        service {
          id
          hcpcs
          description
          prices {
            id
            cost
            date
            provider {
              id
              name
              region {
                id
                code
              }
            }
          }
        }
      }
    }
    attachment_types {
      id
      name
    }
  }
`

export const FETCH_CASE_SERVICES = gql`
  query getCaseServices($case_id: Int!) {
    case_services(where: { case_id: { _eq: $case_id } }) {
      id
      case_id
      service {
        id
        hcpcs
        description
      }
    }
  }
`

export const FETCH_CASE_SERVICES_PRICES = gql`
  query getCaseServicesPrices($id: Int!) {
    cases(where: { id: { _eq: $id } }) {
      id
      case_services {
        id
        case_services_prices {
          id
          price_id
        }
        service_id
      }
    }
  }
`

export const FETCH_REGIONS_SERVICES = gql`
  query fetchRegionsServices {
    regions {
      code
      id
    }
    services(limit: 100) {
      id
      hcpcs
      description
    }
  }
`

export const FETCH_NEW_PRICE_FORM = gql`
  query fetchNewPriceForm {
    regions {
      id
      code
      providers {
        id
        name
      }
    }
  }
`

export const FETCH_NEW_PROVIDER_FORM = gql`
  query fetchNewProviderForm {
    regions {
      id
      code
    }
    provider_types {
      id
      type
    }
  }
`

export const FETCH_SERVICE_BY_HCPCS = gql`
  query fetchServiceByHcpcs($hcpcs: String!) {
    services(where: { hcpcs: { _ilike: $hcpcs } }) {
      id
      hcpcs
      description
    }
  }
`

export const SEARCH_SERVICES = gql`
  query searchServices($search: String_comparison_exp!) {
    services(
      where: { _or: [{ hcpcs: $search }, { description: $search }] }
      limit: 25
    ) {
      id
      hcpcs
      description
    }
  }
`
