import { Navigate, useRoutes } from 'react-router-dom'
// layouts
import DashboardLayout from './layouts/dashboard'
//
import Cases from './pages/Cases'
import Case from './pages/Case'
import NewCase from './pages/NewCase'
import Services from './pages/Services'
import Service from './pages/Service'
import Providers from './pages/Providers'
import Provider from './pages/Provider'
import Regions from './pages/Regions'
import Region from './pages/Region'
import Prices from './pages/Prices'

import store from './store/Store'

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/cases" replace /> },
        { path: 'cases', element: <Cases store={store} /> },
        { path: 'cases/new', element: <NewCase store={store} /> },
        { path: 'cases/:caseId', element: <Case store={store} /> },
        { path: 'services', element: <Services store={store} /> },
        { path: 'services/:serviceId', element: <Service store={store} /> },
        { path: 'providers', element: <Providers store={store} /> },
        { path: 'providers/:providerId', element: <Provider store={store} /> },
        { path: 'regions', element: <Regions store={store} /> },
        { path: 'regions/:regionId', element: <Region store={store} /> },
        { path: 'prices', element: <Prices store={store} /> },
      ],
    },
    // {
    //   path: '/',
    //   element: <LogoOnlyLayout />,
    //   children: [
    //     { path: 'login', element: <Login /> },
    //     { path: 'register', element: <Register /> },
    //     { path: '404', element: <NotFound /> },
    //     { path: '/', element: <Navigate to="/dashboard" /> },
    //     { path: '*', element: <Navigate to="/404" /> },
    //   ],
    // },
    // { path: '*', element: <Navigate to="/404" replace /> },
  ])
}
