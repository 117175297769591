import PropTypes from 'prop-types'
// material
import { Box } from '@mui/material'

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
}

export default function Logo({ sx }) {
  return (
    <Box
      component="img"
      src="/static/ocOval-ede3e385b1341cc05a969646295a5229.png"
      sx={{ width: 40, height: 40, ...sx }}
    />
  )
}
