import React, { useState } from 'react'
import Page from '../components/Page'
import NewServiceForm from '../forms/NewServiceForm'
import {
  Container,
  Stack,
  Typography,
  Button,
  Card,
  Dialog,
  DialogContent,
} from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import plusFill from '@iconify/icons-eva/plus-fill'
import { useQuery } from '@apollo/client'

import camelize from '../utils/camelize'
import { FETCH_SERVICES } from '../store/queries'

const columns = [
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'hcpcs', headerName: 'CPT Code', width: 100 },
  { field: 'description', headerName: 'Description', flex: 1 },
]

const Services = () => {
  const navigate = useNavigate()
  const [isNewServiceFormOpen, setIsNewServiceFormOpen] = useState(false)
  const { loading, error, data } = useQuery(FETCH_SERVICES)
  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error}</div>
  const services = camelize(data).services

  return (
    <Page title="OCP - Services">
      <Dialog
        open={isNewServiceFormOpen}
        onClose={() => setIsNewServiceFormOpen(false)}
      >
        <DialogContent style={{ width: '600px' }}>
          <NewServiceForm onSubmit={() => setIsNewServiceFormOpen(false)} />
        </DialogContent>
      </Dialog>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h3" gutterBottom>
            Services
          </Typography>
          <Button
            variant="contained"
            onClick={() => setIsNewServiceFormOpen(true)}
            startIcon={<Icon icon={plusFill} />}
          >
            New Service
          </Button>
        </Stack>
        <Card>
          <DataGrid
            rows={services}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            autoHeight
            onRowDoubleClick={({ id }) => navigate('/services/' + id)}
            components={{ Toolbar: GridToolbar }}
          />
        </Card>
      </Container>
    </Page>
  )
}

export default Services
