import { snakeCase } from 'lodash'

const snakeize = data => {
  if (!data) return data
  if (Array.isArray(data)) {
    return data.map(snakeize)
  }
  if (typeof data === 'object') {
    return Object.keys(data).reduce((acc, key) => {
      const snakeCaseKey = snakeCase(key)
      const value = data[key]
      return {
        ...acc,
        [snakeCaseKey]: snakeize(value),
      }
    }, {})
  }
  return data
}

export default snakeize
