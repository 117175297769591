/* eslint eqeqeq: "off" */
import { flow, get, find, getOr, map } from 'lodash/fp'
import {
  Box,
  TextField,
  Stack,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import React, { useState } from 'react'
import { Icon } from '@iconify/react'
import sharpSend from '@iconify/icons-ic/sharp-send'
import { useQuery, useMutation } from '@apollo/client'
import {
  FETCH_NEW_PRICE_FORM,
  FETCH_PROVIDER,
  FETCH_PRICES,
  FETCH_CASE,
  FETCH_SERVICE,
  FETCH_REGION,
} from '../store/queries'
import ServicePicker from '../components/ServicePicker'
import { INSERT_PRICE } from '../store/mutations'
import camelize from '../utils/camelize'
import snakeize from '../utils/snakeize'

const getIdOrBlank = getOr('', 'id')

const getIcon = name => <Icon icon={name} width={20} height={20} />

const NewPriceForm = ({
  initialService,
  initialProvider,
  initialRegion,
  onSubmit,
}) => {
  const { data, loading, error } = useQuery(FETCH_NEW_PRICE_FORM)
  const [insertPrice, { error: insertError, loading: insertLoading }] =
    useMutation(INSERT_PRICE, {
      onCompleted: resp => {
        if (onSubmit) onSubmit(resp.insert_prices_one)
      },
      refetchQueries: [
        FETCH_PRICES,
        FETCH_PROVIDER,
        FETCH_CASE,
        FETCH_SERVICE,
        FETCH_REGION,
      ],
    })
  const [formData, setFormData] = useState({
    serviceId: getIdOrBlank(initialService),
    regionId: getIdOrBlank(initialRegion),
    providerId: getIdOrBlank(initialProvider),
    cost: 0,
  })
  if (error) return <div>Error</div>
  if (loading) return <div>Loading...</div>
  if (insertError) return <div>Error creating price</div>
  const { regions } = camelize(data)
  const regionOptions = regions.map(r => ({
    label: r.code,
    value: r.id,
  }))
  const providerOptions = flow(
    find({ id: formData.regionId }),
    get('providers'),
    map(p => ({ label: p.name, value: p.id }))
  )(regions)
  const getChangeHandler = fieldName => e =>
    setFormData({ ...formData, [fieldName]: e.target.value })

  return (
    <Box
      component="form"
      p={3}
      sx={{ width: 500 }}
      onSubmit={e => {
        e.preventDefault()
        const d = new Date()
        const priceData = {
          serviceId: formData.serviceId,
          providerId: formData.providerId,
          cost: formData.cost,
          date: d.toISOString(),
        }
        insertPrice({ variables: { data: snakeize(priceData) } })
      }}
    >
      <Typography mb={3} variant="h4" gutterBottom>
        New Price
      </Typography>
      <Stack spacing={3}>
        <ServicePicker
          onChange={(_, v) => setFormData({ ...formData, serviceId: v.value })}
          required={true}
          initialService={initialService || undefined}
        />
        {/* <Autocomplete
          disabled={!!initialService}
          id="service-select"
          options={serviceOptions}
          sx={{ width: 500 }}
          required
          value={formData.serviceId}
          getOptionLabel={getLabelForService}
          onChange={(_, v) => setFormData({ ...formData, serviceId: v })}
          renderInput={params => <TextField {...params} label="Service*" />}
        /> */}
        <FormControl fullWidth>
          <InputLabel id="region-select-label">Region</InputLabel>
          <Select
            disabled={!!initialRegion}
            labelId="region-select-label"
            id="region-select"
            value={formData.regionId}
            label="Region"
            onChange={getChangeHandler('regionId')}
            required={true}
          >
            {regionOptions.map(o => (
              <MenuItem key={o.value} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="provider-select-label">Provider</InputLabel>
          <Select
            disabled={!!initialProvider}
            labelId="provider-select-label"
            id="provider-select"
            value={formData.providerId}
            label="Provider *"
            required={true}
            onChange={getChangeHandler('providerId')}
          >
            {providerOptions.map(o => (
              <MenuItem key={o.value} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          value={formData.cost}
          onChange={getChangeHandler('cost')}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          label="Price*"
        />
        <Button
          variant="contained"
          color="secondary"
          style={{ width: '125px' }}
          endIcon={getIcon(sharpSend)}
          type="submit"
          disabled={insertLoading}
        >
          SUBMIT
        </Button>
      </Stack>
    </Box>
  )
}

export default NewPriceForm
