/* eslint eqeqeq: "off" */
import { makeAutoObservable } from 'mobx'
import mockCases from '../_mocks_/cases'
import mockServices from '../_mocks_/services'
import mockRegions from '../_mocks_/regions'
import mockProviders from '../_mocks_/providers'
import mockProviderTypes from '../_mocks_/providerTypes'
import mockAttachmentTypes from '../_mocks_/attachmentTypes'
import { flow, flatMap, filter } from 'lodash/fp'

class Store {
  cases = mockCases
  services = mockServices
  regions = mockRegions
  providers = mockProviders
  providerTypes = mockProviderTypes
  attachmentTypes = mockAttachmentTypes

  constructor() {
    makeAutoObservable(this)
  }

  setCases(cases) {
    this.cases = cases
  }

  addCase(x) {
    this.cases.push(x)
  }

  getCase(caseId) {
    return this.cases.find(({ id }) => id == caseId)
  }

  changeCasePrices(caseId, availablePrices, selectedPrices) {
    const _case = this.cases.find(({ id }) => id == caseId)
    const currentPrices = _case.prices
    _case.prices = currentPrices
      .filter(p => !availablePrices.includes(p))
      .concat(selectedPrices)
  }

  setServices(services) {
    this.services = services
  }

  addService(service) {
    this.services.push(service)
  }

  getService(serviceId) {
    return this.services.find(({ id }) => id == serviceId)
  }

  addPrice(price) {
    const i = this.services.findIndex(s => price.service == s.id)
    this.services[i].prices.push(price)
  }

  setRegions(regions) {
    this.regions = regions
  }

  addRegion(region) {
    this.regions.push(region)
  }

  getRegion(regionId) {
    const region = this.regions.find(({ id }) => id == regionId)
    return {
      ...region,
      prices: flow(
        flatMap(service => service.prices.map(p => ({ ...p, service }))),
        filter(p => p.provider.region.id == regionId)
      )(this.services),
    }
  }

  setProviders(providers) {
    this.providers = providers
  }

  addProvider(provider) {
    this.providers.push(provider)
  }

  getProvider(providerId) {
    const provider = this.providers.find(({ id }) => id == providerId)
    return {
      ...provider,
      prices: flow(
        flatMap(service => service.prices.map(p => ({ ...p, service }))),
        filter(p => p.provider.id == providerId)
      )(this.services),
    }
  }

  addProviderType(providerType) {
    this.providerTypes.push(providerType)
  }

  getProviderType(providerTypeId) {
    return this.providerTypes.find(({ id }) => id == providerTypeId)
  }

  getPrices() {
    return flatMap(service => service.prices.map(p => ({ ...p, service })))(
      this.services
    )
  }
}

const store = new Store()

export default store
