import React, { useState } from 'react'
import { Icon } from '@iconify/react'
import plusFill from '@iconify/icons-eva/plus-fill'
import { useNavigate } from 'react-router-dom'
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
} from '@mui/material'
import Page from '../components/Page'
import NewPriceForm from '../forms/NewPriceForm'

import { useQuery } from '@apollo/client'
import camelize from '../utils/camelize'
import { FETCH_REGIONS } from '../store/queries'

const Regions = () => {
  const navigate = useNavigate()
  const [isNewRegionFormOpen, setIsNewRegionFormOpen] = useState(false)
  const { error, loading, data } = useQuery(FETCH_REGIONS)
  if (error) return <div>Error</div>
  if (loading) return <div>Loading...</div>
  const regions = camelize(data).regions
  return (
    <Page title="OCP - Regions">
      <Dialog
        open={isNewRegionFormOpen}
        onClose={() => setIsNewRegionFormOpen(false)}
      >
        <DialogContent>
          <NewPriceForm onSubmit={() => setIsNewRegionFormOpen(false)} />
        </DialogContent>
      </Dialog>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h3" gutterBottom>
            Regions
          </Typography>
          <Button
            variant="contained"
            onClick={() => setIsNewRegionFormOpen(true)}
            startIcon={<Icon icon={plusFill} />}
          >
            New Region
          </Button>
        </Stack>

        <Card>
          <List>
            {regions.map(r => (
              <ListItem key={r.id}>
                <ListItemButton onClick={() => navigate('/regions/' + r.id)}>
                  <ListItemText primary={r.code}></ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Card>
      </Container>
    </Page>
  )
}

export default Regions
