import { Box, TextField, Stack, Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import { flow, get, first } from 'lodash/fp'
import { Icon } from '@iconify/react'
import sharpSend from '@iconify/icons-ic/sharp-send'
import exclamationCircle from '@iconify/icons-ant-design/exclamation-circle-filled'
import { useQuery } from '@apollo/client'
import camelize from '../utils/camelize'
import { FETCH_SERVICE_BY_HCPCS } from '../store/queries'

const getIcon = name => <Icon icon={name} width={20} height={20} />

const NewServiceForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    id: Math.random(),
    description: '',
    hcpcs: '',
  })

  const { data } = useQuery(FETCH_SERVICE_BY_HCPCS, {
    variables: { hcpcs: formData.hcpcs },
  })
  const existingService =
    formData.hcpcs && flow(camelize, get('services'), first)(data)
  const getChangeHandler = fieldName => e =>
    setFormData({ ...formData, [fieldName]: e.target.value })
  return (
    <Box
      component="form"
      p={3}
      onSubmit={e => {
        e.preventDefault()
        // addService({ ...formData, id: Math.random(), prices: [] })
        if (onSubmit) onSubmit()
      }}
    >
      <Typography variant="h4" gutterBottom>
        New Service
      </Typography>
      <Stack spacing={3}>
        <TextField
          value={formData.description}
          label="Description*"
          onChange={getChangeHandler('description')}
        />
        <TextField
          value={formData.hcpcs}
          label="CPT Code"
          onChange={getChangeHandler('hcpcs')}
        />
        {existingService && (
          <>
            <Typography style={{ display: 'flex' }}>
              {getIcon(exclamationCircle)}&nbsp; A service with this CPT code
              exists:&nbsp;<i>{existingService.description}</i>
            </Typography>
          </>
        )}
        <Button
          variant="contained"
          color="secondary"
          style={{ width: '125px' }}
          endIcon={getIcon(sharpSend)}
          type="submit"
        >
          SUBMIT
        </Button>
      </Stack>
    </Box>
  )
}

export default NewServiceForm
