import React, { useState } from 'react'
import { flow, get, map, flatMap } from 'lodash/fp'
import Page from '../components/Page'
import {
  Stack,
  Container,
  Typography,
  Button,
  Dialog,
  DialogContent,
} from '@mui/material'
import { Icon } from '@iconify/react'
import plusFill from '@iconify/icons-eva/plus-fill'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { FETCH_REGION } from '../store/queries'
import { fDate } from '../utils/formatTime'
import NewPriceForm from '../forms/NewPriceForm'
import camelize from '../utils/camelize'

const columns = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'date',
    headerName: 'Date',
    flex: 0.2,
    valueFormatter: ({ value }) => fDate(value),
  },
  { field: 'hcpcs', headerName: 'CPT', flex: 0.1 },
  { field: 'serviceName', headerName: 'Service', flex: 0.3 },
  { field: 'providerName', headerName: 'Provider', flex: 0.3 },
  {
    field: 'cost',
    headerName: 'Price',
    flex: 0.1,
    valueFormatter: ({ value }) => '$' + value,
  },
]

const Region = () => {
  const [isNewPriceFormOpen, setIsNewPriceFormOpen] = useState(false)
  const { regionId } = useParams()
  const { error, loading, data, refetch } = useQuery(FETCH_REGION, {
    variables: { id: regionId },
  })
  if (error) return <div>Error</div>
  if (loading) return <div>Loading...</div>
  const region = flow(camelize, get('regions.0'))(data)
  // const prices = region.prices.map(p => ({
  //   ...p,
  //   hcpcs: p.service.hcpcs,
  //   serviceName: p.service.description,
  //   providerName: p.provider.name,
  // }))
  console.log({ region })
  const prices = flow(
    get('providers'),
    flatMap(get('prices')),
    map(price => ({
      ...price,
      providerName: price.provider.name,
      serviceName: price.service.description,
      hcpcs: price.service.hcpcs,
    }))
  )(region)

  console.log({ prices })

  return (
    <Page title={`OCP - Region ${region.code}`}>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h3" gutterBottom>
            {region.code}
          </Typography>
          <Button
            variant="contained"
            onClick={() => setIsNewPriceFormOpen(true)}
            startIcon={<Icon icon={plusFill} />}
          >
            New Price
          </Button>
        </Stack>
        <Dialog
          open={isNewPriceFormOpen}
          onClose={() => setIsNewPriceFormOpen(false)}
        >
          <DialogContent>
            <NewPriceForm
              initialRegion={region}
              onSubmit={() => {
                setIsNewPriceFormOpen(false)
                refetch()
              }}
            />
          </DialogContent>
        </Dialog>
        <DataGrid
          rows={prices}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          pageSize={10}
          rowsPerPageOptions={[10]}
          autoHeight
        />
      </Container>
    </Page>
  )
}

export default Region
