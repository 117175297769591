const regions = [
  { id: 1, code: 'NONE' },
  { id: 2, code: 'MS-Jackson' },
  { id: 3, code: 'MS-Delta' },
  { id: 4, code: 'MS-Gulfport' },
  { id: 5, code: 'TN-Memphis' },
  { id: 6, code: 'TN-Nashville' },
  { id: 7, code: 'TX-Dallas' },
  { id: 8, code: 'GA-Atlanta' },
  { id: 9, code: 'FL-Jacksonville' },
  { id: 10, code: 'FL-Miami' },
]

export default regions
