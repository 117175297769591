import faker from 'faker'
import { sample } from 'lodash'
import regions from './regions'
import services from './services'

const cases = [...Array(24)].map((_, index) => {
  return {
    id: index,
    region: sample(regions),
    name:
      'Patient #' +
      faker.datatype.number({ min: 1000, max: 9999, precision: 1 }),
    dateCreated: faker.date.past(),
    dateModified: faker.date.recent(),
    dueDate: faker.date.future(),
    status: sample(['new', 'in progress', 'finished']),
    services: [sample(services), sample(services), sample(services)],
    prices: [],
  }
})

export default cases
