import { camelCase } from 'lodash'

const camelize = data => {
  if (!data) return data
  if (Array.isArray(data)) {
    return data.map(camelize)
  }
  if (typeof data === 'object') {
    return Object.keys(data).reduce((acc, key) => {
      const camelCaseKey = camelCase(key)
      const value = data[key]
      return {
        ...acc,
        [camelCaseKey]: camelize(value),
      }
    }, {})
  }
  return data
}

export default camelize
