import React, { useState } from 'react'
import Page from '../components/Page'
import {
  Container,
  Stack,
  Typography,
  Button,
  Card,
  Dialog,
  DialogContent,
} from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Icon } from '@iconify/react'
import plusFill from '@iconify/icons-eva/plus-fill'
import { observer } from 'mobx-react'
import { useQuery } from '@apollo/client'
import { fDate } from '../utils/formatTime'
import camelize from '../utils/camelize'
import NewPriceForm from '../forms/NewPriceForm'
import { FETCH_PRICES } from '../store/queries'

const columns = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'date',
    headerName: 'Date',
    valueFormatter: ({ value }) => fDate(value),
    width: 125,
  },
  { field: 'regionCode', headerName: 'Region', hide: true },
  { field: 'providerName', headerName: 'Provider', flex: 1 },
  { field: 'hcpcs', headerName: 'CPT Code', width: 100 },
  { field: 'description', headerName: 'Description', flex: 1 },
  {
    field: 'cost',
    headerName: 'Price',
    valueFormatter: ({ value }) => '$' + value,
    width: 125,
  },
]

export default observer(({ store }) => {
  const [isNewPriceFormOpen, setIsNewPriceFormOpen] = useState(false)
  const { error, loading, data } = useQuery(FETCH_PRICES)
  if (error) return <div>Error</div>
  if (loading) return <div>Loading...</div>
  const prices = camelize(data).prices.map(p => ({
    ...p,
    providerName: p.provider.name,
    hcpcs: p.service.hcpcs,
    description: p.service.description,
    regionCode: p.provider.region.code,
  }))
  return (
    <Page title="OCP - Prices">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h3" gutterBottom>
            Prices
          </Typography>
          <Button
            variant="contained"
            onClick={() => setIsNewPriceFormOpen(true)}
            startIcon={<Icon icon={plusFill} />}
          >
            New Price
          </Button>
          <Dialog
            open={isNewPriceFormOpen}
            onClose={() => setIsNewPriceFormOpen(false)}
          >
            <DialogContent>
              <NewPriceForm
                store={store}
                onSubmit={() => setIsNewPriceFormOpen(false)}
              />
            </DialogContent>
          </Dialog>
        </Stack>
        <Card>
          <DataGrid
            rows={prices}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            // onRowDoubleClick={({ id }) => navigate('/services/' + id)}
          />
        </Card>
      </Container>
    </Page>
  )
})
