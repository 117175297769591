const providerTypes = [
  { id: 1, type: 'Hospital' },
  { id: 4, type: 'Online Resource' },
  { id: 3, type: 'Specialist' },
  { id: 2, type: 'Primary Care' },
  { id: 5, type: 'Durable Medical Equipment' },
  { id: 6, type: 'Website' },
]

export default providerTypes
