import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import baselineDeleteOutline from '@iconify/icons-ic/baseline-delete-outline'
import plusFill from '@iconify/icons-eva/plus-fill'
import { Icon } from '@iconify/react'

import ServicePicker from '../components/ServicePicker'
import { DELETE_CASE_SERVICES, INSERT_CASE_SERVICES } from '../store/mutations'
import { FETCH_CASE_SERVICES } from '../store/queries'
import camelize from '../utils/camelize'

const CaseServicesForm = ({ caseId }) => {
  const [newCaseService, setNewCaseService] = useState(null)
  const { data, loading, error } = useQuery(FETCH_CASE_SERVICES, {
    variables: {
      case_id: caseId,
    },
  })
  const [deleteCaseServices, { error: deleteError }] = useMutation(
    DELETE_CASE_SERVICES,
    {
      onCompleted: resp => {
        console.log('deleteCaseServices', resp)
      },
      refetchQueries: [FETCH_CASE_SERVICES],
    }
  )
  const [insertCaseServices, { error: insertError }] = useMutation(
    INSERT_CASE_SERVICES,
    {
      onCompleted: resp => {
        console.log('insertCaseServices', resp)
      },
      refetchQueries: [FETCH_CASE_SERVICES],
    }
  )

  const caseServices = camelize(data?.case_services)
  if (error || deleteError || insertError) return <div>Error</div>
  if (loading) return <div>Loading...</div>
  return (
    <Box p={3} sx={{ width: 500 }}>
      <Typography variant="h4" mb={3} gutterBottom>
        Case Services
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>CPT Code</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {caseServices.map(cs => (
              <TableRow key={cs.id}>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      console.log('delete', cs.id)
                      deleteCaseServices({ variables: { id: cs.id } })
                    }}
                    startIcon={<Icon icon={baselineDeleteOutline} />}
                  >
                    Delete
                  </Button>
                </TableCell>
                <TableCell>{cs.service.hcpcs}</TableCell>
                <TableCell>{cs.service.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2} mb={2}>
        <ServicePicker onChange={(_, v) => setNewCaseService(v.value)} />
      </Box>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<Icon icon={plusFill} />}
        onClick={() => {
          insertCaseServices({
            variables: {
              data: {
                case_id: caseId,
                service_id: newCaseService,
              },
            },
          })
        }}
      >
        Add
      </Button>
    </Box>
  )
}

export default CaseServicesForm
