import React, { useState } from 'react'
import Page from '../components/Page'
import {
  Stack,
  Card,
  Container,
  Typography,
  Button,
  Dialog,
  DialogContent,
} from '@mui/material'
import { Icon } from '@iconify/react'
import plusFill from '@iconify/icons-eva/plus-fill'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import camelize from '../utils/camelize'
import { FETCH_SERVICE } from '../store/queries'
import { fDate } from '../utils/formatTime'
import NewPriceForm from '../forms/NewPriceForm'

const columns = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'date',
    headerName: 'Date',
    width: 150,
    valueFormatter: ({ value }) => fDate(value),
  },
  {
    field: 'cost',
    headerName: 'Price',
    width: 150,
    valueFormatter: ({ value }) => '$' + value,
  },
  { field: 'regionName', headerName: 'Region', width: 150 },
  { field: 'providerName', headerName: 'Provider', flex: 1 },
]

const Service = () => {
  const [isNewPriceFormOpen, setIsNewPriceFormOpen] = useState(false)
  const params = useParams()
  const { error, loading, data } = useQuery(FETCH_SERVICE, {
    variables: { id: params.serviceId },
  })
  if (error) return <div>Error</div>
  if (loading) return <div>Loading...</div>
  const service = camelize(data).services[0]

  service.prices = service.prices.map(p => ({
    ...p,
    providerName: p.provider.name,
    regionName: p.provider.region.code,
  }))

  return (
    <Page title={`OCP - Service ${service.description}`}>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h3" gutterBottom>
            {service.hcpcs} - {service.description}
          </Typography>
          <Button
            variant="contained"
            onClick={() => setIsNewPriceFormOpen(true)}
            startIcon={<Icon icon={plusFill} />}
          >
            New Price
          </Button>
        </Stack>
        <Dialog
          open={isNewPriceFormOpen}
          onClose={() => setIsNewPriceFormOpen(false)}
        >
          <DialogContent>
            <NewPriceForm
              initialService={service}
              onSubmit={() => setIsNewPriceFormOpen(false)}
            />
          </DialogContent>
        </Dialog>
        <Card>
          <DataGrid
            rows={service.prices}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            pageSize={10}
            rowsPerPageOptions={[10]}
            autoHeight
          />
        </Card>
      </Container>
    </Page>
  )
}

export default Service
