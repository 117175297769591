import React, { useEffect, useState } from 'react'
import { debounce, noop } from 'lodash'
import { Autocomplete, TextField, CircularProgress } from '@mui/material'
import { useLazyQuery } from '@apollo/client'
import { SEARCH_SERVICES } from '../store/queries'

const defaultOption = { id: -1, label: 'Select a service' }
const makeLabel = service => `${service.hcpcs} - ${service.description}`

const ServicePicker = ({ onChange, multiple, required, initialService }) => {
  const [search, setSearch] = useState(
    initialService ? makeLabel(initialService) : ''
  )
  console.log({ search })
  const [fetchServices, { loading, error, data }] = useLazyQuery(
    SEARCH_SERVICES,
    {
      variables: { search: { _ilike: `%${search}%` } },
    }
  )
  useEffect(
    () =>
      debounce(() => {
        if (search && !initialService) fetchServices()
      }, 300),
    [search, fetchServices, initialService]
  )
  if (error) return <div>Error</div>
  const services = (function () {
    if (initialService) return [initialService]
    if (data) return data.services
    return [defaultOption]
  })()
  const serviceOptions = services.map(service => ({
    label: service.id >= 0 ? makeLabel(service) : '',
    value: service.id,
  }))
  console.log({ serviceOptions })
  return (
    <Autocomplete
      required={!!required}
      id="service-picker"
      options={serviceOptions}
      value={initialService?.value}
      inputValue={search}
      onChange={!initialService ? onChange : noop}
      onInputChange={
        !initialService
          ? (event, newInputValue) => {
              setSearch(newInputValue)
            }
          : noop
      }
      disabled={!!initialService}
      multiple={!!multiple}
      renderInput={params => (
        <TextField
          {...params}
          label={'Service' + (multiple ? 's' : '') + (required ? '*' : '')}
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default ServicePicker
