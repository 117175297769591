import {
  Paper,
  Box,
  TextField,
  Stack,
  Button,
  Container,
  Typography,
  Autocomplete,
} from '@mui/material'
import { flow, first, map, get, find } from 'lodash/fp'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import Page from '../components/Page'
import ServicePicker from '../components/ServicePicker'
import React, { useState } from 'react'
import { Icon } from '@iconify/react'
import sharpSend from '@iconify/icons-ic/sharp-send'
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { FETCH_REGIONS, FETCH_CASES } from '../store/queries'
import { INSERT_CASE, INSERT_CASE_SERVICES } from '../store/mutations'
import camelize from '../utils/camelize'
import snakeize from '../utils/snakeize'

const getIcon = name => <Icon icon={name} width={20} height={20} />

const NewCase = ({ store }) => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    name: '',
    region: '',
    dueDate: new Date(),
    services: [],
  })
  const { data, loading, error } = useQuery(FETCH_REGIONS)
  const [insertCase, { error: insertError, loading: insertLoading }] =
    useMutation(INSERT_CASE)
  const [
    insertCaseServices,
    { error: insertCaseServicesError, loading: insertCaseServicesLoading },
  ] = useMutation(INSERT_CASE_SERVICES, {
    onCompleted: resp => {
      const caseId = flow(
        camelize,
        get('insertCaseServices'),
        get('returning'),
        first,
        get('caseId')
      )(resp)
      navigate('/cases/' + caseId)
    },
    refetchQueries: [FETCH_CASES],
  })
  if (error) return <div>Error</div>
  if (loading) return <div>Loading...</div>
  if (insertError || insertCaseServicesError)
    return <div>Error creating case</div>
  const { regions } = camelize(data)
  const regionOptions = regions.map(r => ({ label: r.code, value: r.id }))
  const getChangeHandler = fieldName => e =>
    setFormData({ ...formData, [fieldName]: e.target.value })

  return (
    <Page title="OCP - New Case">
      <Container>
        <Paper elevation={3}>
          <Box
            component="form"
            p={3}
            onSubmit={e => {
              e.preventDefault()
              const regionId = flow(
                find({ code: formData.region }),
                get('id')
              )(regions)
              insertCase({
                variables: {
                  data: snakeize({
                    name: formData.name,
                    regionId,
                    dueDate: formData.dueDate.toISOString(),
                    dateCreated: new Date().toISOString(),
                  }),
                },
              }).then(resp => {
                const caseId = get('data.insert_cases_one.id')(resp)
                insertCaseServices({
                  variables: {
                    data: flow(
                      get('services'),
                      map(s => ({ caseId, serviceId: s.value })),
                      snakeize
                    )(formData),
                  },
                })
              })
            }}
          >
            <Typography variant="h4" gutterBottom>
              New Case
            </Typography>
            <Stack spacing={3}>
              <TextField
                required
                // helperText="Do not enter patient's name"
                label="Case name (Do not enter patient's name or any other identifying information)"
                variant="outlined"
                sx={{ width: '500px' }}
                value={formData.name}
                onChange={getChangeHandler('name')}
              />
              <Autocomplete
                id="region-select"
                options={regionOptions}
                sx={{ width: 500 }}
                required
                value={formData.region}
                onChange={(_, v) =>
                  setFormData({ ...formData, region: v.label })
                }
                renderInput={params => (
                  <TextField {...params} label="Region*" />
                )}
              />
              <Box width={500}>
                <DesktopDatePicker
                  label="Due date"
                  inputFormat="MM/dd/yyyy"
                  value={formData.dueDate}
                  onChange={v => setFormData({ ...formData, dueDate: v })}
                  renderInput={params => <TextField {...params} />}
                />
              </Box>
              <ServicePicker
                multiple={true}
                onChange={(_, v) => setFormData({ ...formData, services: v })}
              />
              {/* <Autocomplete
                multiple
                id="services-input"
                options={serviceOptions}
                getOptionLabel={o => o.hcpcs + ' ' + o.description}
                disableCloseOnSelect
                value={formData.services}
                onChange={(_, v) => setFormData({ ...formData, services: v })}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Services"
                    placeholder="Services"
                  />
                )}
              /> */}
              <Button
                variant="contained"
                color="primary"
                style={{ width: '125px' }}
                endIcon={getIcon(sharpSend)}
                type="submit"
                disabled={insertLoading || insertCaseServicesLoading}
              >
                SUBMIT
              </Button>
            </Stack>
          </Box>
        </Paper>
      </Container>
    </Page>
  )
}

export default NewCase
